import { get, readable, writable } from 'svelte/store';
// console.log('*** stores_site.js ***');

import { cfg } from '../stores';
import { api } from 'aether_npm_lib';

export let slct_site_id = writable(null);
export let slct_site_obj = writable({});
export let slct_site_obj_li = writable([]);


// Updated 2022-07-13
export let load_site_obj = async function load_site_obj({site_id, inc_site_domain_list=false, params={}, log_lvl=0}) {
    console.log('*** stores_site.js: load_site_obj() ***');

    const endpoint = `/site/${site_id}`;
    if (inc_site_domain_list) {
       params['inc_site_domain_list'] = true;
    }
    let site_obj_get_promise = await api.get_object({api_cfg: get(cfg).api, endpoint: endpoint, params: params, log_lvl: log_lvl});

    let site_obj = site_obj_get_promise;
    return site_obj;
}


// Updated 2022-07-13
export let get_site_obj_li = async function get_site_obj_li({account_id, inc_site_domain_list=false, params={}, return_meta=false, log_lvl=0}) {
    console.log('*** stores_site.js: get_site_obj_li() ***');

    const endpoint = `/account/${account_id}/site/list`;
    if (inc_site_domain_list) {
       params['inc_site_domain_list'] = true;
    }
    let site_obj_li_get_promise = await api.get_object({api_cfg: get(cfg).api, endpoint: endpoint, params: params, return_meta: return_meta, log_lvl: log_lvl});

    let site_obj_li = site_obj_li_get_promise;
    return site_obj_li;
}


// Updated 2022-02-10
export let update_site_obj = async function update_site_obj({site_id, params={}, data={}, return_obj=false, return_meta=false, log_lvl=0}) {
    console.log('*** stores_site_api.js: update_site_obj() ***');

    let endpoint = `/site/${site_id}`;
    if (return_obj) {
        params['return_obj'] = true;
        params['inc_site_domain_list'] = true;
    }
    let site_obj_patch_promise = await api.patch_object({api_cfg: get(cfg).api, endpoint: endpoint, params: params, data: data, return_obj: return_obj, return_meta: return_meta, log_lvl: log_lvl});
    // console.log(site_obj_patch_promise);
    if (return_obj) {
        return site_obj_patch_promise;
    } else {
        return site_obj_patch_promise.site_id_random;
    }
}