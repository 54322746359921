<script lang="ts">
import { createEventDispatcher, onMount } from 'svelte';

import { ae, Element_input, Element_modal_v3 } from 'aether_npm_lib';

// import Modal_v3_element from '../mods/element_modal_v3.svelte';
import { cfg, client, page, ae_com } from '../stores';
import { slct_site_id, slct_site_obj, slct_site_obj_li, load_site_obj, get_site_obj_li, update_site_obj } from './stores_site.js';

let site_obj_get_promise = undefined;
let site_obj_li_get_promise = undefined;
let site_obj_update_promise = undefined;

let slct_obj_prop: { obj_id: string, obj_type: string, name: string, value: any, index: number };

const dispatch = createEventDispatcher();


onMount(() => {
    console.log('Site Main component has mounted');

    if ($client.account_id) {
        handle_get_site_obj_li($client.account_id);
    }
});

async function handle_get_site_obj_li(account_id) {
    console.log('*** handle_get_site_obj_li() ***');

    let params = { inc_site_domain_list: true }
    site_obj_li_get_promise = await get_site_obj_li({ account_id: account_id, inc_site_domain_list: true, params: params, log_lvl: 2 });
    slct_site_obj_li.set(site_obj_li_get_promise);

    return $slct_site_obj_li;
}

async function handle_update_site_field(site_id, field_name , value) {
    console.log('*** handle_update_site_field() ***');
    console.log(`Field Name: ${field_name}; Value: ${value}`);

    let data = {};
    if (field_name) {
        data[field_name] = value;
    } else {
        return false;
    }

    site_obj_update_promise = await update_site_obj({site_id:site_id, data: data, log_lvl: 2});

    // site_obj[field_name] = value;

    // slct_site_obj.set(site_obj);
    // console.log($slct_site_obj);

    dispatch(
        'site_obj_updated',
        {
            site_id: site_id,
            // site_obj: site_obj,
        }
    );

    return site_obj_update_promise;
}

</script>


<section id="Main-Content" class="site_main">
    <head>
        <h1>Manage Account Sites</h1>
    </head>

    {#await site_obj_li_get_promise}
        Getting list...
    {:then result}
        {#if $slct_site_obj_li}
            <h2>Site(s) for Account</h2>
            {#each $slct_site_obj_li as site_obj, index}
                {@const obj_template = ae.input_template.site}

                <section class="ae_obj obj_site">

                <h3>{site_obj.name}</h3>

                <div class="ae_prop prop_title">
                    <button
                        on:click={() => {
                            slct_obj_prop = {};
                            slct_obj_prop.obj_id = site_obj.site_id_random;
                            slct_obj_prop.obj_type = obj_template.title.obj_type;
                            slct_obj_prop.name = obj_template.title.obj_prop_name;
                            slct_obj_prop.value = site_obj.title;
                            slct_obj_prop.index = index;
                            }}
                            class="ae_btn btn_sm btn_seamless show_modal_btn"
                            title="Show"
                        >
                        <span class="fas fa-expand"></span>
                        <span class="ae_btn_text">Show</span>
                    </button>

                    <span class="ae_prop_label">{obj_template.title.label}:</span>
                    <span class="ae_prop_value">
                        {#if site_obj.title}
                            <pre>{site_obj.title}</pre>
                        {:else}
                            <span class="ae_prop_value_none">-- not set --</span>
                        {/if}
                    </span>
                </div>

                <div class="ae_prop prop_tagline">
                    <button
                        on:click={() => {
                            slct_obj_prop = {};
                            slct_obj_prop.obj_id = site_obj.site_id_random;
                            slct_obj_prop.obj_type = obj_template.tagline.obj_type;
                            slct_obj_prop.name = obj_template.tagline.obj_prop_name;
                            slct_obj_prop.value = site_obj.tagline;
                            slct_obj_prop.index = index;
                            }}
                            class="ae_btn btn_sm btn_seamless show_modal_btn"
                            title="Show"
                        >
                        <span class="fas fa-expand"></span>
                        <span class="ae_btn_text">Show</span>
                    </button>

                    <span class="ae_prop_label">{obj_template.tagline.label}:</span>
                    <span class="ae_prop_value">
                        {#if site_obj.tagline}
                            <pre>{site_obj.tagline}</pre>
                        {:else}
                            <span class="ae_prop_value_none">-- not set --</span>
                        {/if}
                    </span>
                </div>

                <div class="ae_prop prop_header_html">
                    <button
                        on:click={() => {
                            slct_obj_prop = {};
                            slct_obj_prop.obj_id = site_obj.site_id_random;
                            slct_obj_prop.obj_type = obj_template.header_html.obj_type;
                            slct_obj_prop.name = obj_template.header_html.obj_prop_name;
                            slct_obj_prop.value = site_obj.header_html;
                            slct_obj_prop.index = index;
                            }}
                            class="ae_btn btn_sm btn_seamless show_modal_btn"
                            title="Show"
                        >
                        <span class="fas fa-expand"></span>
                        <span class="ae_btn_text">Show</span>
                    </button>

                    <span class="ae_prop_label">{obj_template.header_html.label}:</span>
                    <span class="ae_prop_value">
                        {#if site_obj.header_html}
                            <pre>{site_obj.header_html}</pre>
                        {:else}
                            <span class="ae_prop_value_none">-- not set --</span>
                        {/if}
                    </span>
                </div>

                <div class="ae_prop prop_header_css">
                    <button
                        on:click={() => {
                            slct_obj_prop = {};
                            slct_obj_prop.obj_id = site_obj.site_id_random;
                            slct_obj_prop.obj_type = obj_template.header_css.obj_type;
                            slct_obj_prop.name = obj_template.header_css.obj_prop_name;
                            slct_obj_prop.value = site_obj.header_css;
                            slct_obj_prop.index = index;
                            }}
                            class="ae_btn btn_sm btn_seamless show_modal_btn"
                            title="Show"
                        >
                        <span class="fas fa-expand"></span>
                        <span class="ae_btn_text">Show</span>
                    </button>

                    <span class="ae_prop_label">{obj_template.header_css.label}:</span>
                    <span class="ae_prop_value">
                        {#if site_obj.header_css}
                            <pre>{site_obj.header_css}</pre>
                        {:else}
                            <span class="ae_prop_value_none">-- not set --</span>
                        {/if}
                    </span>
                </div>

                <div class="ae_prop prop_header_image_path">
                    <button
                        on:click={() => {
                            slct_obj_prop = {};
                            slct_obj_prop.obj_id = site_obj.site_id_random;
                            slct_obj_prop.obj_type = obj_template.header_image_path.obj_type;
                            slct_obj_prop.name = obj_template.header_image_path.obj_prop_name;
                            slct_obj_prop.value = site_obj.header_image_path;
                            slct_obj_prop.index = index;
                            }}
                            class="ae_btn btn_sm btn_seamless show_modal_btn"
                            title="Show"
                        >
                        <span class="fas fa-expand"></span>
                        <span class="ae_btn_text">Show</span>
                    </button>

                    <span class="ae_prop_label">{obj_template.header_image_path.label}:</span>
                    <span class="ae_prop_value">
                        {#if site_obj.header_image_path}
                            <pre>{site_obj.header_image_path}</pre>
                        {:else}
                            <span class="ae_prop_value_none">-- not set --</span>
                        {/if}
                    </span>
                </div>

                <div class="ae_prop prop_body_html">
                    <button
                        on:click={() => {
                            slct_obj_prop = {};
                            slct_obj_prop.obj_id = site_obj.site_id_random;
                            slct_obj_prop.obj_type = obj_template.body_html.obj_type;
                            slct_obj_prop.name = obj_template.body_html.obj_prop_name;
                            slct_obj_prop.value = site_obj.body_html;
                            slct_obj_prop.index = index;
                            }}
                            class="ae_btn btn_sm btn_seamless show_modal_btn"
                            title="Show"
                        >
                        <span class="fas fa-expand"></span>
                        <span class="ae_btn_text">Show</span>
                    </button>

                    <span class="ae_prop_label">{obj_template.body_html.label}:</span>
                    <span class="ae_prop_value">
                        {#if site_obj.body_html}
                            <pre class="max_height_8_scroll">{site_obj.body_html}</pre>
                        {:else}
                            <span class="ae_prop_value_none">-- not set --</span>
                        {/if}
                    </span>
                </div>

                <div class="ae_prop prop_logo_path ">
                    <button
                        on:click={() => {
                            slct_obj_prop = {};
                            slct_obj_prop.obj_id = site_obj.site_id_random;
                            slct_obj_prop.obj_type = obj_template.logo_path.obj_type;
                            slct_obj_prop.name = obj_template.logo_path.obj_prop_name;
                            slct_obj_prop.value = site_obj.logo_path;
                            slct_obj_prop.index = index;
                            }}
                            class="ae_btn btn_sm btn_seamless show_modal_btn"
                            title="Show"
                        >
                        <span class="fas fa-expand"></span>
                        <span class="ae_btn_text">Show</span>
                    </button>

                    <span class="ae_prop_label">{obj_template.logo_path.label}:</span>
                    <span class="ae_prop_value">
                        {#if site_obj.logo_path}
                            <pre>{site_obj.logo_path}</pre>
                        {:else}
                            <span class="ae_prop_value_none">-- not set --</span>
                        {/if}
                    </span>
                </div>

                <div class="ae_prop prop_logo_bg_color ">
                    <button
                        on:click={() => {
                            slct_obj_prop = {};
                            slct_obj_prop.obj_id = site_obj.site_id_random;
                            slct_obj_prop.obj_type = obj_template.logo_bg_color.obj_type;
                            slct_obj_prop.name = obj_template.logo_bg_color.obj_prop_name;
                            slct_obj_prop.value = site_obj.logo_bg_color;
                            slct_obj_prop.index = index;
                            }}
                            class="ae_btn btn_sm btn_seamless show_modal_btn"
                            title="Show"
                        >
                        <span class="fas fa-expand"></span>
                        <span class="ae_btn_text">Show</span>
                    </button>

                    <span class="ae_prop_label">{obj_template.logo_bg_color.label}:</span>
                    <span class="ae_prop_value">
                        {#if site_obj.logo_bg_color}
                            <pre>{site_obj.logo_bg_color}</pre>
                        {:else}
                            <span class="ae_prop_value_none">-- not set --</span>
                        {/if}
                    </span>
                </div>

                <div class="ae_prop prop_style_href ">
                    <button
                        on:click={() => {
                            slct_obj_prop = {};
                            slct_obj_prop.obj_id = site_obj.site_id_random;
                            slct_obj_prop.obj_type = obj_template.style_href.obj_type;
                            slct_obj_prop.name = obj_template.style_href.obj_prop_name;
                            slct_obj_prop.value = site_obj.style_href;
                            slct_obj_prop.index = index;
                            }}
                            class="ae_btn btn_sm btn_seamless show_modal_btn"
                            title="Show"
                        >
                        <span class="fas fa-expand"></span>
                        <span class="ae_btn_text">Show</span>
                    </button>

                    <span class="ae_prop_label">{obj_template.style_href.label}:</span>
                    <span class="ae_prop_value">
                        {#if site_obj.style_href}
                            <pre>{site_obj.style_href}</pre>
                        {:else}
                            <span class="ae_prop_value_none">-- not set --</span>
                        {/if}
                    </span>
                </div>

                <div class="ae_prop prop_script_src ">
                    <button
                        on:click={() => {
                            slct_obj_prop = {};
                            slct_obj_prop.obj_id = site_obj.site_id_random;
                            slct_obj_prop.obj_type = obj_template.script_src.obj_type;
                            slct_obj_prop.name = obj_template.script_src.obj_prop_name;
                            slct_obj_prop.value = site_obj.script_src;
                            slct_obj_prop.index = index;
                            }}
                            class="ae_btn btn_sm btn_seamless show_modal_btn"
                            title="Show"
                        >
                        <span class="fas fa-expand"></span>
                        <span class="ae_btn_text">Show</span>
                    </button>

                    <span class="ae_prop_label">{obj_template.script_src.label}:</span>
                    <span class="ae_prop_value">
                        {#if site_obj.script_src}
                            <pre>{site_obj.script_src}</pre>
                        {:else}
                            <span class="ae_prop_value_none">-- not set --</span>
                        {/if}
                    </span>
                </div>

                <div class="ae_prop prop_google_tracking_id">
                    <button
                        on:click={() => {
                            slct_obj_prop = {};
                            slct_obj_prop.obj_id = site_obj.site_id_random;
                            slct_obj_prop.obj_type = obj_template.google_tracking_id.obj_type;
                            slct_obj_prop.name = obj_template.google_tracking_id.obj_prop_name;
                            slct_obj_prop.value = site_obj.google_tracking_id;
                            slct_obj_prop.index = index;
                            }}
                            class="ae_btn btn_sm btn_seamless show_modal_btn"
                            title="Show"
                        >
                        <span class="fas fa-expand"></span>
                        <span class="ae_btn_text">Show</span>
                    </button>

                    <span class="ae_prop_label">{obj_template.google_tracking_id.label}:</span>
                    <span class="ae_prop_value">
                        {#if site_obj.google_tracking_id}
                            <pre>{site_obj.google_tracking_id}</pre>
                        {:else}
                            <span class="ae_prop_value_none">-- not set --</span>
                        {/if}
                    </span>
                </div>

                </section>


                <section>
                <h3>Site Domain Name List</h3>

                <button class="ae_btn btn_default"><span class="fas fa-plus"></span> Add Domain Name</button>

                {#if site_obj.site_domain_list}
                    <table>
                    {#each site_obj.site_domain_list as site_domain_obj, index}
                        <tr>
                        <td>{site_domain_obj.fqdn}</td>
                        <td>{site_domain_obj.access_key}</td>
                        <td>{site_domain_obj.required_referrer}</td>
                        <td>{site_domain_obj.valid_for}</td>
                        </tr>
                    {/each}
                    </table>
                {/if}
                </section>
            {/each}
        {/if}
    {/await}

</section>


{#if slct_obj_prop}
<Element_modal_v3
        show={ true }
        on:close={ () => {slct_obj_prop=null;} }
        >

    <span slot="header_title">Edit Property</span>

    <span slot="body">
        <Element_input
            {...ae.input_template[slct_obj_prop.obj_type][slct_obj_prop.name]}
            label=""
            bind:value={slct_obj_prop.value}
            use_name_prefix={true}
            required
            class_li={[]}
            container_class_li={[]}
            />
        <button
            class="ae_btn btn_warning"
            on:click={async () => {
                let saving_promise = handle_update_site_field(slct_obj_prop.obj_id, slct_obj_prop.name, slct_obj_prop.value)
                .then(function (result) {
                    $slct_site_obj_li[slct_obj_prop.index][slct_obj_prop.name] = slct_obj_prop.value
                    slct_obj_prop = null;
                });
                }}
            >
            {#await site_obj_update_promise}
                Saving...
            {:then}
                <span class="fas fa-save"></span> Save
            {/await}
        </button>
    </span>
</Element_modal_v3>
{/if}
