<script lang="ts">
import { onMount, tick } from 'svelte';
import { fade, fly } from 'svelte/transition';

import { account_id, client, page } from '../stores';
import { get_user_obj_auth_key } from '../user/stores_user_api.js';

import Person_user_create from './10_person_user_create.svelte';
import Person_user_login from './10_person_user_login.svelte';
import Person_user_form from './10_person_user_form.svelte';

export let purpose: string = 'login' // login or create

export let auth_key: string = null;
export let person_id: string; // Required to get started
// export let user_id: string = null;

// export let allow_create_person_obj_user = true; // This will show person name fields
let show_logged_in_options = false;
let show_person_user_form = false; // This will show person user form instead of create or login options
let show_create_or_login = false;

let show_fundraising_option = true;
let show_membership_option = true;
let show_event_option = true;

// let content_view: string = 'person_user_contact_address'; // person_user_contact_address, membership


/* BEGIN: Lifecycle functions (onMount, beforeUpdate, afterUpdate,
onDestroy, tick) */
onMount(async () => {
    console.log('Person User Main component has mounted');

    if ($client.logged_in_check) {
        show_logged_in_options = true;

        if (person_id && $client.administrator_check) {
            console.log('Passed administrator check');
            show_person_user_form = true;
        } else if (person_id && $client.person_id == person_id) {
            console.log('Passed logged in as matching person check');
            show_person_user_form = true;
        } else if (person_id && auth_key) {
            console.log('Passed initial auth_key check');
            show_person_user_form = true; // May get denied if look up fails
        }
    } else {
        show_create_or_login = true;
    }
});
/* END: Lifecycle functions (onMount, beforeUpdate, afterUpdate,
onDestroy, tick) */

/* BEGIN: Handle requests (archive, create, hide, remove, select, update, POST, PATCH, GET, DELETE) */
/* END: Handle requests (archive, create, hide, remove, select, update, POST, PATCH, GET, DELETE) */

/* BEGIN: Handle other local actions (show/hide form, process data) */
function toggle_purpose(event) {
    console.log(event);

    event.target.innerText = 'Change Button Text?';

    if (purpose == 'login') {
        purpose = 'create';
    } else if (purpose == 'create') {
        purpose = 'login';
    } else {
        purpose = 'create'; // Just in case
    }
}
/* END: Handle other local actions (show/hide form, process data) */

/* BEGIN: Handle children events (archived, canceled, closed, created, deleted, hidden, updated) */
function handle_person_user_success(event) {
    console.log('*** handle_person_user_success() ***');
}
function handle_person_user_created(event) {
    console.log('*** handle_person_user_created() ***');

    show_fundraising_option = true;
    show_membership_option = true;
    show_event_option = true;

    let person_obj = event.detail.person_obj;
    console.log(person_obj);

    handle_person_user_check_auto_login(person_obj);
}

async function handle_person_user_updated(event) {
    console.log('*** handle_person_user_updated() ***');

    show_fundraising_option = true;
    show_membership_option = true;
    show_event_option = true;

    let person_obj = event.detail.person_obj;
    console.log(person_obj);

    handle_person_user_check_auto_login(person_obj);
}

async function handle_person_user_check_auto_login(person_obj) {
    if (person_obj.person_id_random && person_obj.contact && person_obj.contact.address && person_obj.user) {
        console.log('All key objects found');
        if (!person_obj.given_name) { return; }
        console.log(person_obj.given_name);
        if (!person_obj.enable) { return; }
        console.log(person_obj.enable);

        if (!person_obj.user.user_id_random) { return; }
        console.log(person_obj.user.user_id_random);
        if (!person_obj.user.email) { return; }
        console.log(person_obj.user.email);
        if (!person_obj.user.username) { return; }
        console.log(person_obj.user.username);
        if (!person_obj.user.enable) { return; }
        console.log(person_obj.user.enable);

        if (!person_obj.contact.email) { return; }
        console.log(person_obj.contact.email);

        // if (!person_obj.contact.address.postal_code) { return; }
        if (!person_obj.contact.address.city) { return; }
        console.log(person_obj.contact.address.city);

        console.log('All checks passed!');
        if (!$client.logged_in_check) {
            let get_user_obj_auth_key_result = await get_user_obj_auth_key({user_id:person_obj.user.user_id_random, return_obj:true, log_lvl: 2});

            let new_auth_key = get_user_obj_auth_key_result.auth_key;

            console.log(new_auth_key);

            console.log(page);

            let auth_key_login_url = `/main/person_user?user_id=${person_obj.user.user_id_random}&auth_key=${new_auth_key}&valid_email=true`;
            console.log(auth_key_login_url);
            window.location.href = auth_key_login_url;
            // goto(auth_key_login_url);
        }
    } else {
        console.log('Missing one or more parts of person_obj');
        console.log(person_obj.person_id_random);
        console.log(person_obj.contact);
        console.log(person_obj.contact.address);
        console.log(person_obj.user);
    }
}

/* END: Handle children events (archived, canceled, closed, created, deleted, hidden, updated) */
</script>


<section class="component obj_type_main person_user_main container">
    <header>
        <h2 style="">Main: Person/User</h2>
    </header>

    {#if show_logged_in_options}
        <p>Manage your account.</p>
        {#if show_person_user_form}
            <button on:click={() => show_person_user_form=false} class="ae_btn btn_warning"><span class="fas fa-sync"></span> Hide Your Information</button>
        {:else}
            <button on:click={() => show_person_user_form=true} class="ae_btn btn_primary"><span class="fas fa-sync"></span> Edit Your Information</button>
        {/if}

        {#if show_fundraising_option}
            <a href="/fundraising" class="ae_btn btn_outline_primary float-end"><span class="fas fa-hand-holding-usd"></span> Make a Contribution</a>
        {/if}

        {#if show_membership_option}
            <a href="/membership" class="ae_btn btn_outline_primary float-end"><span class="fas fa-id-card"></span> Membership Options</a>
        {/if}

        <!-- {#if show_event_option}<button class="ae_btn btn_default">Event Registration</button>{/if} -->
    {/if}

    {#if show_create_or_login}
        <p>Use this page to sign in with your email address or start creating a new account.</p>
        {#if purpose == 'login'}
            <button on:click={toggle_purpose} class="ae_btn btn_warning"><span class="fas fa-sync"></span> Create Account?</button>
        {:else if purpose == 'create'}
            <button on:click={toggle_purpose} class="ae_btn btn_primary"><span class="fas fa-sync"></span> Email Sign Link?</button>
        {/if}

        {#if purpose == 'login'}
            <Person_user_login on:person_user_success={handle_person_user_success} />
        {:else if purpose == 'create'}
            <Person_user_create on:person_user_success={handle_person_user_success} />
        {/if}
    {/if}

    {#if show_person_user_form}
        <Person_user_form on:person_user_created={handle_person_user_created} on:person_user_updated={handle_person_user_updated} person_id={person_id} auth_key={auth_key} />
    {/if}
</section>


<style>
</style>
