<script lang="ts">
// *** Import Svelte core
import { onMount } from 'svelte';

// *** Import Aether core variables and functions
import { ae, api, Element_modal_v3 } from 'aether_npm_lib';
import { client, cfg, page, slct, slct_trigger, ae_com } from '../stores';
// import { get_data_store_obj_w_code } from '../data_store/stores_data_store_api.js';

// *** Import Aether core components
import Access_type_element from '../mods/element_access_type.svelte';

// *** Import Aether module variables and functions
// import { ae_person } from './stores_person.js';
import { get_person_obj } from './stores_person_api.js';
import { person_defaults } from './stores_person_defaults.js';

// *** Import Aether module components
import Person_id_crud from './10_person_id_crud.svelte';
// import Person_list from './10_person_list.svelte';

// *** Export/Exposed variables and functions for component

// *** Set initial variables
$slct.person_id = $page['page_for']['person'];
$slct_trigger = 'person';

let person_obj_get_promise = null;
// let person_obj_update_promise = null;

// let person_data = null;

let show_disabled: boolean = false; // param enabled = 'enabled', 'disabled', 'all'
let show_hidden: boolean = false; // param hidden = 'hidden', 'not_hidden', 'all'

let show_modal_person_crud = false;
let redirect_to = null;


onMount(async () => {
    console.log('** Component Mounted: ** Person ID View Main');

    if (!$slct.person_id) {
        $slct.person_id = null;
        $slct.person_obj = { 'contact': { 'address': {} } };
        show_modal_person_crud = true;
    }
});

$: if ($slct_trigger == 'person' && $slct.person_id) {
    console.log(`Selected Person: ${$slct.person_id}`);
    $slct_trigger = null;

    handle_load_person_obj({person_id: $slct.person_id, try_cache: false});

    handle_build_main_nav_menu();
}

async function handle_load_person_obj({person_id=null, try_cache=true}){
    console.log('*** handle_load_person_obj() ***');
    // console.log($slct.person_id);

    if (person_id) {
        let params = { inc_address: true, inc_contact: true, inc_organization: true, enabled: 'enabled', hidden: 'not_hidden', limit: 200 };

        if (show_disabled) {
            params['enabled'] = 'all'
        }

        if (show_hidden) {
            params['hidden'] = 'all'
        }

        person_obj_get_promise = get_person_obj({ person_id: person_id, inc_address: true, inc_contact: true, inc_organization: true, params: params, log_lvl: 0 });

        person_obj_get_promise.then(function (person_obj_result) {
            if (!person_obj_result) {
                console.log('The result was null or false when trying to get the person.');
                return;
            }

            if (JSON.stringify($slct.person_obj) === JSON.stringify(person_obj_result)) {
                console.log('No change in person obj result.');
            } else {
                $slct.person_obj = person_obj_result;
            }
            // console.log($slct.person_obj);

            // if (JSON.stringify($slct.person_obj_li) === JSON.stringify($slct.person_obj.person_list)) {
            //     console.log('No change in person location obj li result.');
            // } else {
            //     $slct.person_obj_li = $slct.person_obj.person_list; // NOTE: using set(), not $
            // }
            // console.log('Person Location Object List:', $slct.person_obj_li);

            // if (JSON.stringify($slct.person_obj_li) === JSON.stringify($slct.person_obj.person_list)) {
            //     console.log('No change in person session obj li result.');
            // } else {
            //     $slct.person_obj_li = $slct.person_obj.person_list; // NOTE: using set(), not $
            // }
            // console.log('Person Session Object List:', $slct.person_obj_li);
        })
        .catch(function (error) {
            console.log(error);
            return false;
        });
    } else {
        return;
    }

    return person_obj_get_promise;
}

function handle_person_obj_created(person) {
    console.log('*** handle_person_obj_created() ***');

    show_modal_person_crud = false;

    if ($client.manager_access || $ae_com.manager_access) {
        if (confirm(`Person was created. Load person as browser client session. ${$slct.person_id}`)) {
            if ($page.params.redirect_to) {
                window.location.href = `/person/load/${$slct.person_id}?redirect_to=${$page.params.redirect_to}`;
            } else {
                window.location.href = `/person/load/${$slct.person_id}`;
            }
        }
    } else {
        alert(`A new person record was created. ID: ${$slct.person_id}`);

        if ($page.params.redirect_to) {
            window.location.href = `/person/load/${$slct.person_id}?redirect_to=${$page.params.redirect_to}`;
        } else {
            window.location.href = `/person/load/${$slct.person_id}`;
        }
    }

    handle_load_person_obj({person_id: $slct.person_id});

    return true;
}

function handle_person_obj_deleted(person) {
    console.log('*** handle_person_obj_deleted() ***');

    show_modal_person_crud = false;

    if (confirm('Person was deleted. Refresh this person page.')) {
        window.location.href = `/person/${$slct.person_id}`;
    } else {
        handle_load_person_obj({person_id: $slct.person_id});
    }

    return true;
}

function handle_person_obj_updated(person) {
    console.log('*** handle_person_obj_updated() ***');

    show_modal_person_crud = false;

    // NOTE: This is may need to be refined. Just because a redirect_to was or was not passed does not mean the person load ID should or should not be run. -2022-12-01
    if ($page.params.redirect_to) {
        // window.location.href = $page.params.redirect_to;
        window.location.href = `/person/load/${$slct.person_id}?redirect_to=${$page.params.redirect_to}`;
    } else {
        handle_load_person_obj({person_id: $slct.person_id});
    }

    return true;
}

function handle_build_main_nav_menu() {
    $ae_com.main_nav_menu = {};

    let menu_item_tmp = {};


    // Navigation button group
    $ae_com.main_nav_menu['navigation'] = []; // group

    menu_item_tmp = Object.assign({}, ae.defaults.default_btn);
    menu_item_tmp['label'] = '<span class="fas fa-eye"></span> User Information';
    menu_item_tmp['title'] = 'View User Information';
    menu_item_tmp['href'] = `/user`;
    menu_item_tmp['class_li'].push('btn_sm', 'btn_outline_secondary');
    $ae_com.main_nav_menu['navigation'].push(menu_item_tmp);

    menu_item_tmp = Object.assign({}, $person_defaults.person_view_id_btn);
    menu_item_tmp['label'] = '<span class="fas fa-eye"></span> Person Information';
    menu_item_tmp['title'] = 'View Person Information';
    menu_item_tmp['href'] = `/person`; // `/person/${$slct.person_id}`
    menu_item_tmp['class_li'].push('btn_sm', 'btn_outline_secondary');
    $ae_com.main_nav_menu['navigation'].push(menu_item_tmp);

    menu_item_tmp = Object.assign({}, ae.defaults.default_btn);
    menu_item_tmp['label'] = '<span class="fas fa-file-invoice-dollar"></span> Order History';
    menu_item_tmp['title'] = 'Order History';
    menu_item_tmp['href'] = `/order`;
    menu_item_tmp['class_li'].push('btn_sm', 'btn_outline_secondary');
    $ae_com.main_nav_menu['navigation'].push(menu_item_tmp);

    if ($client.order_cart_v3_id) {
        menu_item_tmp = Object.assign({}, ae.defaults.cart_btn);
        // menu_item_tmp['label'] = '<span class="fas fa-file-invoice-dollar"></span> Order History';
        // menu_item_tmp['title'] = 'Order History';
        menu_item_tmp['href'] = `/order/cart/view`;
        menu_item_tmp['class_li'].push('btn_sm', 'btn_outline_secondary');
        $ae_com.main_nav_menu['navigation'].push(menu_item_tmp);
    }



    // Admin button group
    $ae_com.main_nav_menu['admin'] = []; // group

    // menu_item_tmp = $person_defaults.person_manage_btn;
    // menu_item_tmp['href'] = `/person/manage`;
    // menu_item_tmp['class_li'].push('btn_sm', 'btn_outline_warning');
    // $ae_com.main_nav_menu['navigation'].push(menu_item_tmp);

    // menu_item_tmp = $person_defaults.person_edit_btn;
    // menu_item_tmp['href'] = `/person/${$slct.person_id}/edit`;
    // menu_item_tmp['class_li'].push('btn_sm', 'btn_outline_warning');
    // $ae_com.main_nav_menu['admin'].push(menu_item_tmp);

    menu_item_tmp = $person_defaults.person_create_btn_modal;
    menu_item_tmp['href'] = null;
    menu_item_tmp['class_li'].push('btn_sm', 'btn_outline_warning');
    menu_item_tmp['callback'] = create_person_btn_callback;
    $ae_com.main_nav_menu['admin'].push(menu_item_tmp);


    // Help button group
    // $ae_com.main_nav_menu['help'] = []; // group

    // menu_item_tmp = ae.defaults.help_btn;
    // menu_item_tmp['class_li'].push('btn_sm', 'btn_outline_info');
    // $ae_com.main_nav_menu['help'].push(menu_item_tmp);


    return true;
}

function create_person_btn_callback(group_item) {
    console.log(group_item);

    $slct.person_id = null;
    $slct.person_obj = {};

    // show_modal_person_crud = true;

    if (confirm(`${group_item.confirm}`)) {
        show_modal_person_crud = true;
        return true;
    } else {
        return false;
    }

    return true;
}

$: if ($slct.person_id) {
    // console.log('Person ID:', $slct.person_id);
}
</script>


<section id="person_id_view_main" class="person_id_view_main">

{#await person_obj_get_promise}
    Loading initial data...
{:then}

{#if $slct.person_id && $slct.person_obj}
    {#if redirect_to}
        <a href="url_for order.order_cart_view redirect_to=redirect_to)" class="ae_btn btn_outline_primary float-end"><span class="fas fa-shopping-cart"></span> Back to Cart</a>
    {/if}
    <header>
        <h1>Person: <span id="obj__person--header" class="obj__person--header">{$slct.person_obj.display_name}</span></h1>
    </header>

    <!-- <div id="account__name--cont" class="account__name--cont">Account name: <span id="account__name"></span></div> -->
    {#if $client.administrator_access || $ae_com.administrator_access}
        <div id="person__id_random--cont" class="person__id_random--cont" class:d_none={!$slct.person_obj.person_id_random}>ID: <span id="person__id_random">{$slct.person_obj.person_id_random}</span></div>
    {/if}

    <section class="obj__person--name">
        <div>Given name: <span id="person__given_name">{$slct.person_obj.given_name}</span></div>
        <div>Middle name: <span id="person__middle_name">{$slct.person_obj.middle_name}</span></div>
        <div>Family name: <span id="person__family_name">{$slct.person_obj.family_name}</span></div>
        <div>Full name: <span id="person__full_name">{$slct.person_obj.full_name}</span> (Automatically generated from the other parts of your name.)</div>
        <div>Display name: <span id="person__display_name">{$slct.person_obj.display_name}</span></div>
    </section>

    <section class="obj__person--affiliations" class:d_none={!$slct.person_obj.affiliations}>
        <div class:d_none={!$slct.person_obj.affiliations}>Organization affiliations: <span id="person__affiliations">{$slct.person_obj.affiliations}</span></div>
    </section>

    <section class="obj__person--contact">
        <h2>Contact Information</h2>
        <div class:d_none={!$slct.person_obj.contact.email}>Email: <span id="person__contact__email">{$slct.person_obj.contact.email}</span></div>
        <div class:d_none={!$slct.person_obj.contact.phone_mobile}>Phone mobile: <span id="person__contact__phone_mobile">{$slct.person_obj.contact.phone_mobile}</span></div>
    </section>

    <section class="obj__person--address">
        <h2>Address</h2>
        <div class:d_none={!$slct.person_obj.contact.address.name}>Location name: <span id="person__contact__address__name">{$slct.person_obj.contact.address.name}</span></div>
        <div class:d_none={!$slct.person_obj.contact.address.line_1}>Line 1: <span id="person__contact__address__line_1">{$slct.person_obj.contact.address.line_1}</span></div>
        <div class:d_none={!$slct.person_obj.contact.address.line_2}>Line 2: <span id="person__contact__address__line_2">{$slct.person_obj.contact.address.line_2}</span></div>
        <div class:d_none={!$slct.person_obj.contact.address.line_3}>Line 3: <span id="person__contact__address__line_3">{$slct.person_obj.contact.address.line_3}</span></div>
        <div class:d_none={!$slct.person_obj.contact.address.city}>City: <span id="person__contact__address__city">{$slct.person_obj.contact.address.city}</span></div>
        <div class:d_none={!$slct.person_obj.contact.address.state_province}>State/Province: <span id="person__contact__address__country_subdivision_name">{$slct.person_obj.contact.address.country_subdivision_name}</span></div>
        <div class:d_none={!$slct.person_obj.contact.address.postal_code}>Postal code or zip code: <span id="person__contact__address__postal_code">{$slct.person_obj.contact.address.postal_code}</span></div>
        <div class:d_none={!$slct.person_obj.contact.address.country_name}>Country: <span id="person__contact__address__country">{$slct.person_obj.contact.address.country_name} ({$slct.person_obj.contact.address.country_alpha_2_code})</span></div>
    </section>

    <!-- <button id="btn_create_update_person" type="button" data-bs-toggle="modal" data-bs-target="#create_update_person" data-bs-keyboard="true" class="ae_btn btn_outline_primary m-1"><span class="fas fa-user-cog"></span> Update Personal Information</button> -->

    <button
        on:click={() => {
            show_modal_person_crud = true;
        }}
        class="ae_btn btn_lg btn_outline_primary m-1"
        title="Update personal information"
        >
        <span class="fas fa-user-cog"></span> Update Personal Information
    </button>

    <hr>

    <!-- {#if not session.logged_in and session.person.id_random} -->
    <div style="font-size: .8rem;">
        <div>
            <a href="{$page.current_url_root}person/load/{$slct.person_id}" class="ae_btn btn_sm">
                <span class="fas fa-link"></span> Personal Link:
            </a>
            <span>{$page.current_url_root}person/load/{$slct.person_id}</span>
        </div>
        <div>
            You may use this link later to reload this personal profile along with the order history.
        </div>
    </div>
    <!-- {/if} -->

{:else if $slct.person_id}
    <p>Person data has not loaded yet.</p>
{:else}
    <button
    on:click={() => {
        $slct.person_id = null;
        $slct.person_obj = { 'contact': { 'address': {} } };
        show_modal_person_crud = true;
    }}
    class="ae_btn btn_lg btn_outline_primary m-1"
    title="Update personal information"
    >
    <span class="fas fa-user-cog"></span> Add Your Information
    </button>
{/if}

{/await}

</section>


{#if show_modal_person_crud}
<Element_modal_v3
    show = { true }
    modal_cover_body = { true }
    on:close={() => {
        show_modal_person_crud = false;

        // $slct.person_id = null;
        // $slct.person_obj = {};
    }}
    >

    <span slot="header_title">Add or Edit Person</span>
    <span slot="body">

        <Person_id_crud
            on:person_obj_created={handle_person_obj_created}
            on:person_obj_deleted={handle_person_obj_deleted}
            on:person_obj_updated={handle_person_obj_updated}
            />

    </span>

</Element_modal_v3>
{/if}


<Access_type_element />


<style>
</style>
