import { readable, writable } from 'svelte/store';
// console.log('*** stores.ts ***');
// BEGIN: TESTING
// window.localStorage.setItem('test_value', '!!!!!'); // This is a test value.
// window.sessionStorage.setItem('test_value', '!!!!!!'); // This is a test value.
// console.log('Checking for storage events???!!!!');
// window.onstorage = () => {
// When local storage changes, dump the list to
// the console.
// console.log('Storage event!!!!');
// window.parent.postMessage('Storage event message!!!!', "*");
// let test_value = window.localStorage.getItem('test_value');
// console.log(test_value);
// console.log(JSON.parse(window.sessionStorage.getItem('test_value')));
// console.log(window.sessionStorage.getItem('test_value'));
//   };
/* FAKE DATA */
// 'https://dev-fastapi.oneskyit.com' or 'http://dev-fastapi.oneskyit.local:5005'
// let fake_base_url_remote_dev = 'https://dev-fastapi.oneskyit.com';
// let fake_base_url_local_dev = 'http://fastapi-local.oneskyit.com';
let fake_api_base_url = 'http://dev-bak-api.oneskyit.local:5005';
let fake_api_base_url_backup = 'https://dev-bak-api.oneskyit.com';
// Set the various commonly used IDs
let temp_holder = null;
/* *** BEGIN *** Pull in Account ID */
temp_holder = (document.aether.client.account_id && typeof document.aether.client.account_id === 'string' && document.aether.client.account_id.length >= 11) ? document.aether.client.account_id : null;
export const account_id = readable(temp_holder);
/* *** BEGIN *** Pull in Aether Config data */
// These are global to the Aether system and not specific to a client or a page.
// Includes: app, api, db, and idb
let aether_cfg_data = (typeof document.aether.cfg !== 'undefined') ? document.aether.cfg : null;
console.log(`Aether Config Data Set by Flask:`, aether_cfg_data);
console.log(`Aether App Config Data:`, aether_cfg_data.app);
/* *** BEGIN *** Pull in Client (App) Device Browser data */
// Includes: account_id, mode (app_mode or client_mode), order(_cart), order(_cart)_id, person, person_group, person_id, site_id, site_domain_id, theme, user, user_group, user_id, ux_mode, various roles and permissions check results
let aether_client_data = (typeof document.aether.client !== 'undefined') ? document.aether.client : {};
console.log(`Aether Client Data Set by Flask:`, aether_client_data);
// NOTE: The path should include a leading front slash
let base_url = `${aether_cfg_data.api.protocol}://${aether_cfg_data.api.server}:${aether_cfg_data.api.port}${aether_cfg_data.api.path}`;
console.log('Base URL:', base_url);
aether_cfg_data.api.base_url = base_url;
let base_url_backup = `${aether_cfg_data.api.protocol_backup}://${aether_cfg_data.api.server_backup}:${aether_cfg_data.api.port_backup}${aether_cfg_data.api.path_backup}`;
aether_cfg_data.api.base_url_backup = base_url_backup;
// NOTE: This API CRUD super key thing being here should be short term! -2023-05-02
aether_cfg_data.api.api_crud_super_key = aether_client_data.api_crud_super_key;
let api_cfg_data = {};
// api_cfg_data['base_url'] = (base_url) ? base_url : fake_api_base_url;
// api_cfg_data['base_url_backup'] = (base_url_backup) ? base_url_backup : fake_api_base_url_backup;
api_cfg_data['base_url'] = (aether_cfg_data.api.base_url !== 'undefined') ? aether_cfg_data.api.base_url : fake_api_base_url;
api_cfg_data['base_url_backup'] = (aether_cfg_data.api.base_url_backup !== 'undefined') ? aether_cfg_data.api.base_url_backup : fake_api_base_url_backup;
api_cfg_data['headers'] = {};
api_cfg_data['headers']['Access-Control-Allow-Origin'] = '*';
api_cfg_data['headers']['content-type'] = 'application/json';
api_cfg_data['headers']['x-aether-api-key'] = (aether_cfg_data.api.secret_key !== 'undefined') ? aether_cfg_data.api.secret_key : '';
api_cfg_data['headers']['x-aether-api-token'] = 'fake-temp-token';
api_cfg_data['headers']['x-aether-api-expire-on'] = '';
api_cfg_data['headers']['x-account-id'] = document.aether.client.account_id;
console.log(`Aether API Config Data:`, api_cfg_data);
aether_cfg_data['api']['headers'] = api_cfg_data['headers'];
console.log(`All Aether App and API Config Data:`, aether_cfg_data);
window.localStorage.setItem('ae_cfg', JSON.stringify(aether_cfg_data)); // Also set in main HTML template files
export const cfg = readable(aether_cfg_data);
/* *** *** *** BEGIN NEEDS WORK *** *** *** */
// Started 2022-10-28
// User access type
// access_type = null; // , 'manager', 'administrator', 'support', 'assistant', 'trusted', 'verified', 'provisional', 'public', 'authenticated', 'anonymous'
// access_timeout = 600;
// access_administrator_passcode = '11500';
// access_trusted_passcode = '19111';
// access_authenticated_passcode = '20902';
// aether_client_data['access_type'] = aether_client_data.access_type; // Should be pulled in above
// aether_client_data['access_timeout'] = aether_client_data.access_timeout; // Should be pulled in above
// User experience
// ux_mode was app_mode (null, default, onsite, native)
// aether_client_data['ux_mode'] = aether_client_data.ux_mode; // Should be pulled in above
// aether_client_data['ux_mode'] = null;
// aether_client_data['theme'] = aether_client_data.theme; // Should be pulled in above
// aether_client_data['theme'] = null; // null, 'light', 'dark', 'contrast'
function process_permission_checks(access_type) {
    // let access_checks = { 'access_type': null, 'super_check': null };
    let access_checks = {};
    if (access_type == 'super') {
        access_checks.access_type = 'super';
        access_checks.super_check = true;
        access_checks.manager_check = false;
        access_checks.administrator_check = false;
        access_checks.support_check = false;
        access_checks.assistant_check = false;
        access_checks.trusted_check = false;
        access_checks.verified_check = false;
        access_checks.provisional_check = false;
        access_checks.public_check = false;
        access_checks.authenticated_check = true;
        access_checks.anonymous_check = false;
        access_checks.super_access = true;
        access_checks.manager_access = true;
        access_checks.administrator_access = true;
        access_checks.support_access = true;
        access_checks.assistant_access = true;
        access_checks.trusted_access = true;
        access_checks.verified_access = true;
        access_checks.provisional_access = true;
        access_checks.public_access = true;
        access_checks.authenticated_access = true;
        access_checks.anonymous_access = true;
    }
    else if (access_type == 'manager') {
        common_data.access_type = 'manager';
        access_checks.super_check = false;
        access_checks.manager_check = true;
        access_checks.administrator_check = false;
        access_checks.support_check = false;
        access_checks.assistant_check = false;
        access_checks.trusted_check = false;
        access_checks.verified_check = false;
        access_checks.provisional_check = false;
        access_checks.public_check = false;
        access_checks.authenticated_check = true;
        access_checks.anonymous_check = false;
        access_checks.super_access = false;
        access_checks.manager_access = true;
        access_checks.administrator_access = true;
        access_checks.support_access = true;
        access_checks.assistant_access = true;
        access_checks.trusted_access = true;
        access_checks.verified_access = true;
        access_checks.provisional_access = true;
        access_checks.public_access = true;
        access_checks.authenticated_access = true;
        access_checks.anonymous_access = true;
    }
    else if (access_type == 'administrator') {
        access_checks.access_type = 'administrator';
        access_checks.super_check = false;
        access_checks.manager_check = false;
        access_checks.administrator_check = true;
        access_checks.support_check = false;
        access_checks.assistant_check = false;
        access_checks.trusted_check = false;
        access_checks.verified_check = false;
        access_checks.provisional_check = false;
        access_checks.public_check = false;
        access_checks.authenticated_check = false;
        access_checks.anonymous_check = false;
        access_checks.super_access = false;
        access_checks.manager_access = false;
        access_checks.administrator_access = true;
        access_checks.support_access = true;
        access_checks.assistant_access = true;
        access_checks.trusted_access = true;
        access_checks.verified_access = true;
        access_checks.provisional_access = true;
        access_checks.public_access = true;
        access_checks.authenticated_access = true;
        access_checks.anonymous_access = true;
    }
    else if (access_type == 'trusted') {
        access_checks.access_type = 'trusted';
        access_checks.super_check = false;
        access_checks.manager_check = false;
        access_checks.administrator_check = false;
        access_checks.support_check = false;
        access_checks.assistant_check = false;
        access_checks.trusted_check = true;
        access_checks.verified_check = false;
        access_checks.provisional_check = false;
        access_checks.public_check = false;
        access_checks.authenticated_check = true;
        access_checks.anonymous_check = false;
        access_checks.super_access = false;
        access_checks.manager_access = false;
        access_checks.administrator_access = false;
        access_checks.support_access = false;
        access_checks.assistant_access = false;
        access_checks.trusted_access = true;
        access_checks.verified_access = true;
        access_checks.provisional_access = true;
        access_checks.public_access = true;
        access_checks.authenticated_access = true;
        access_checks.anonymous_access = true;
    }
    else if (access_type == 'authenticated') {
        access_checks.access_type = 'authenticated';
        access_checks.super_check = false;
        access_checks.manager_check = false;
        access_checks.administrator_check = false;
        access_checks.support_check = false;
        access_checks.assistant_check = false;
        access_checks.trusted_check = false;
        access_checks.verified_check = false;
        access_checks.provisional_check = false;
        access_checks.public_check = false;
        access_checks.authenticated_check = true;
        access_checks.anonymous_check = false;
        access_checks.super_access = false;
        access_checks.manager_access = false;
        access_checks.administrator_access = false;
        access_checks.support_access = false;
        access_checks.assistant_access = false;
        access_checks.trusted_access = false;
        access_checks.verified_access = false;
        access_checks.provisional_access = false;
        access_checks.public_access = false;
        access_checks.authenticated_access = true;
        access_checks.anonymous_access = true;
    }
    else {
        access_checks.access_type = 'anonymous';
        access_checks.super_check = false;
        access_checks.manager_check = false;
        access_checks.administrator_check = false;
        access_checks.support_check = false;
        access_checks.assistant_check = false;
        access_checks.trusted_check = false;
        access_checks.verified_check = false;
        access_checks.provisional_check = false;
        access_checks.public_check = false;
        access_checks.authenticated_check = false;
        access_checks.anonymous_check = true;
        access_checks.super_access = false;
        access_checks.manager_access = false;
        access_checks.administrator_access = false;
        access_checks.support_access = false;
        access_checks.assistant_access = false;
        access_checks.trusted_access = false;
        access_checks.verified_access = false;
        access_checks.provisional_access = false;
        access_checks.public_access = false;
        access_checks.authenticated_access = false;
        access_checks.anonymous_access = true;
    }
    return access_checks;
}
let access_checks_results = process_permission_checks(aether_client_data.access_type);
aether_client_data = Object.assign(Object.assign({}, aether_client_data), access_checks_results);
aether_client_data.process_permission_checks = process_permission_checks;
let client_access_type_value = window.localStorage.getItem('access_type');
if (client_access_type_value) {
    access_checks_results = process_permission_checks(client_access_type_value);
    aether_client_data = Object.assign(Object.assign({}, aether_client_data), access_checks_results);
}
console.log(`Client Data:`, aether_client_data);
window.localStorage.setItem('ae_client', JSON.stringify(aether_client_data));
export const client = readable(aether_client_data);
/* *** BEGIN *** Pull in Page data */
// Includes: mode, page_for, theme (override client per page), ux_mode, unknowns
let aether_page_data = { 'page_for': {} };
aether_page_data = (typeof document.aether.page !== 'undefined') ? document.aether.page : { 'page_for': {} };
aether_page_data['theme'] = null; // null, 'light', 'dark', 'contrast'
aether_page_data['ux_mode'] = null; // null, authenticated (key, pin, or passcode), onsite_admin, onsite_trusted, onsite_public
console.log(`Page Data:`, aether_page_data);
window.localStorage.setItem('ae_page', JSON.stringify(aether_page_data));
export const page = readable(aether_page_data);
/* *** BEGIN *** Pull or set common Svelte App data */
// This should only be things specific to the Svelte application
// This ux_mode should override the client ux_mode and page ux_mode???
let common_data = {};
common_data['access_type'] = aether_client_data.access_type; // null, authenticated (key, pin, or passcode), administrator (onsite_admin), trusted (onsite_trusted), public, etc
// NOTE: These access_*_check values are also set in element_access_type.svelte
// NOTE: There should be something generally watching for the access_type value to change so that the related values can be updated.
// NOTE: On changes should this go back and take into account the value from the client permissions and roles?
// let access_checks_results =  process_permission_checks(aether_client_data.access_type)
common_data = Object.assign(Object.assign({}, common_data), access_checks_results);
common_data['access_timeout'] = aether_client_data.access_timeout; // datetime UTC
// common_data['administrator_passcode'] = aether_client_data.administrator_passcode; // '11500';
// common_data['trusted_passcode'] = aether_client_data.trusted_passcode; // '19111';
// common_data['authenticated_passcode'] = aether_client_data.authenticated_passcode; // '20902';
common_data['app_mode'] = aether_client_data.app_mode; // Avoid using! null, default, onsite, native
common_data['mode'] = aether_client_data.mode; // Avoid using! null, default, onsite, native
common_data['ux_mode'] = aether_client_data.ux_mode; // null, default, onsite, native
common_data['theme'] = aether_client_data.theme; // null, 'light', 'dark', 'contrast'
// This is the general site navigation menu for a client account
common_data['site_nav_menu'] = null;
// This is the main content navigation menu for specific pages with specific types of content
common_data['main_nav_menu'] = null;
// This is the main content floating navigation menu for special purposes
common_data['main_floating_nav_menu'] = null;
// This is for the apps core modules settings and data
// app core: qr
common_data['app'] = {};
console.log('Common Data', common_data);
window.localStorage.setItem('ae_com', JSON.stringify(common_data));
export let ae_com = writable(common_data);
/* Do this later if can change the stores.ts to TypeScript */
/*
let client_data: {
    account_id: string;
    person_id: string;
    user_id: string;
};
*/
/* *** BEGIN *** Initialize slct variable */
// Updated 2022-10-28
export let slct_trigger = writable(null);
let slct_obj_template = { 'archive_id': null, 'archive_obj': null, 'archive_obj_li': null, 'archive_content_id': null, 'archive_content_obj': null, 'archive_content_obj_li': null, 'event_id': null, 'event_obj': {}, 'event_badge_id': null, 'event_badge_obj': {}, 'event_badge_template_id': null, 'event_badge_template_obj': {}, 'event_device_id': null, 'event_device_obj': {}, 'event_device_obj_li': [], 'event_exhibit_id': null, 'event_exhibit_obj': {}, 'event_file_id': null, 'event_file_obj': {}, 'event_file_obj_li': [], 'event_file_obj_internal_use_li': [], 'event_location_id': null, 'event_location_obj': {}, 'event_location_obj_li': null, 'event_presentation_id': null, 'event_presentation_obj': {}, 'event_presentation_obj_li': [], 'event_presenter_id': null, 'event_presenter_obj': {}, 'event_presenter_obj_li': [], 'event_session_id': null, 'event_session_obj': {}, 'event_session_obj_li': null, 'event_track_id': null };
export let slct = writable(slct_obj_template);
// Not really used?
// export let slct_id = writable({ 'archive_id': null, 'archive_content_id': null, 'event_id': null, 'event_badge_id': null, 'event_device_id': null, 'event_exhibit_id': null, 'event_file_id': null, 'event_location_id': null, 'event_presentation_id': null, 'event_presenter_id': null, 'event_session_id': null, 'event_track_id': null });
// Not really used?
export let slct_obj = writable({ 'archive': null, 'archive_content': null, 'event': null, 'event_badge': null, 'event_device': null, 'event_exhibit': null, 'event_file': null, 'event_location': null, 'event_presentation': null, 'event_presenter': null, 'event_session': null, 'event_track': null });
// Not really used?
// export let slct_obj_li = writable({ 'archive': null, 'archive_content': null, 'archive_content': null, 'archive_content_li': null, 'event': null, 'event_badge': null, 'event_device': null, 'event_exhibit': null, 'event_file': null, 'event_location': null, 'event_presentation': null, 'event_presenter': null, 'event_session': null, 'event_track': null });
/* *** BEGIN *** Create time variable */
// Updated 2020
export const time = readable(new Date(), function start(set) {
    const interval = setInterval(() => {
        set(new Date());
    }, 1000);
    return function stop() {
        clearInterval(interval);
    };
});
/* *** BEGIN *** Create network status variable */
// There may be a better way to do this, but so far this seems to work well. 2022-03-10
let network_status_data = null;
if (navigator.onLine) {
    network_status_data = 'online';
}
else {
    network_status_data = 'offline';
}
export let network_status = writable(network_status_data);
window.addEventListener('offline', function (e) { console.log('offline'); network_status.set('offline'); });
window.addEventListener('online', function (e) { console.log('online'); network_status.set('online'); });
/* *** BEGIN *** Set some classes and related */
if (aether_client_data.order_cart_v3_id) {
    console.log(`Order Cart ID: ${aether_client_data.order_cart_v3_id}`);
}
if (aether_client_data.order_cart_v3) {
    console.log(aether_client_data.order_cart_v3);
    document.getElementById('Body-Container').classList.add('cart_found');
    // document.getElementById('System-Nav-Menu').classList.add('cart_found');
    // document.getElementById('System-Nav-Menu').style.height = '2.4rem';
    // document.getElementById('System-Nav-Menu').style.maxHeight = '2.4rem';
    // document.querySelector('.system_menu').classList.add('cart_found');
    // document.querySelector('.system_menu').style.maxHeight = '2.4rem';
}
if (aether_client_data.super_access) {
    console.log('Super');
    document.getElementById('Body-Container').classList.add('user_super');
}
else if (aether_client_data.manager_access) {
    console.log('Manager');
    document.getElementById('Body-Container').classList.add('user_manager');
}
else if (aether_client_data.administrator_access) {
    console.log('Administrator');
    document.getElementById('Body-Container').classList.add('user_administrator');
}
else if (aether_client_data.manager_access) {
    console.log('Manager');
    document.getElementById('Body-Container').classList.add('user_manager');
}
else if (aether_client_data.authenticated_access) {
    console.log('Authenticated');
    document.getElementById('Body-Container').classList.add('user_authenticated');
}
else {
    document.getElementById('Body-Container').classList.add('user_anonymous');
}
/* *** END *** Set some classes and related */
// /* This should not be hardcoded. Fix later! -STI 2021-08-05 */
// let cont_edu_cert_data = {};
// if (aether_client_data.account_id == '_XY7DXtc9MY') {
// 	cont_edu_cert_data['cont_edu_cert_purchase_button_text'] = 'Purchase OSIT CE Completion Certificate';
// 	cont_edu_cert_data['cont_edu_cert_product_id'] = 'MGIJMjO5CDY'; // or use '9ZR6f40o7t8'
// 	cont_edu_cert_data['cont_edu_cert_product_amount'] = 9850;
// 	cont_edu_cert_data['cont_edu_cert_id'] = 'mOTuOOxG4Lo'; // or use '9m3nmjpqZYI'
// } else if (aether_client_data.account_id == 'Q8lR8Ai8hx2FjbQ3C_EH1Q') { // NOTE: For IDAA only!
// 	cont_edu_cert_data['cont_edu_cert_purchase_button_text'] = 'Purchase IDAA CME Completion Certificate';
// 	cont_edu_cert_data['cont_edu_cert_product_id'] = 'vgq12nBOIAA'; // or use 'amGuYnE8DTQ'
// 	cont_edu_cert_data['cont_edu_cert_product_amount'] = 16000;
// 	cont_edu_cert_data['cont_edu_cert_id'] = '3vqsbpWjd9s'; // or use 'bCv8WBzdRJQ'
// } else {
// 	cont_edu_cert_data['cont_edu_cert_purchase_button_text'] = 'Purchase OSIT CE Completion Certificate 2';
// 	cont_edu_cert_data['cont_edu_cert_product_id'] = '9ZR6f40o7t8'; // or use 'MGIJMjO5CDY'
// 	cont_edu_cert_data['cont_edu_cert_product_amount'] = 9950;
// 	cont_edu_cert_data['cont_edu_cert_id'] = '9m3nmjpqZYI'; // or use 'mOTuOOxG4Lo'
// }
// // console.log(cont_edu_cert_data);
// export const cont_edu_cert = readable(cont_edu_cert_data);
// export let value_no_null = function value_to_null(value) {
// 	if (value) {
// 		return value;
// 	} else {
// 		return null;
// 	}
// }
// export let testing = writable(null);
// export let testing_str = writable('testing string goes here');
// export let testing_bool = writable(true);
// export let testing_obj = writable({});
/* *** BEGIN *** Important AE Bridge adapter for separate Svelte apps */
// Updated 2023-03-10
// const shared_test_obj = {
// 	fooValue: 'bar',
// 	get foo() {
// 		return this.fooValue;
// 	},
// 	set foo(val) {
// 			this.fooValue = val;
// 			this.fooListener(val);
// 	},
// 	fooListener: function (val) {},
// 	registerNewListener: function (externalListenerFunction) {
// 		this.fooListener = externalListenerFunction;
// 	},
// };
ae_bridge.registerNewCoreListener((core_shared_test_value) => console.log(`Shared obj new value in core: ${core_shared_test_value}`));
ae_bridge.example = 'Core am I now?';
ae_bridge.register_core_access_type_listener((new_value) => console.log(`Bridge to Core: Access Type: ${new_value}`));
ae_bridge.register_core_ae_com_listener((new_value) => console.log(`Bridge to Core: AE Common: ${new_value}`));
/* *** END *** Important AE Bridge adapter for separate Svelte apps */
// window.localStorage.setItem('test_value', 'Change local storage!!! This is a test value.');
// window.sessionStorage.setItem('test_value', 'Change session storage!!! This is a test value.');
