<script lang="ts">
import { onMount } from 'svelte';

let group_id = '';
let group_id_1 = 'test_grp_123';
let group_id_2 = 'test_grp_999';
let group_id_3 = 'test_grp_poster';

let client_id = Date.now();


// JSON formatted data
let ws_data = {
    'client_id': null, // The device or browser ID if available.
    'account_id': null, // Essentially the person ID or user ID if available.
    'target': 'echo', // echo, dm (direct), grp (group), all (broadcast)
    'type': 'msg', // msg, cmd, json, hello, bye
    'msg': null,
    'cmd': null,
    'data': null,
    'b64': null,
}


let dm_client_id = '';

let message_text = null;

let message_list = [];

let ws_received_list_grp_1 = [];
let ws_received_list_grp_2 = [];

let message_list_grp_2 = [];
let message_list_grp_3 = [];

let cmd_list = [];
let cmd_list_grp_1 = [];
let cmd_list_grp_2 = [];
let cmd_list_grp_3 = [];
// let dm_message_list = [];
// let client_message_list = [];

// let ws = new WebSocket(`wss://dev-api.oneskyit.com/ws/${client_id}`);

// let ws_client = new WebSocket(`wss://dev-api.oneskyit.com/ws/client/${client_id}`);

// let ws_group_1 = new WebSocket(`wss://dev-api.oneskyit.com/ws/group/${group_id_1}`);
// let ws_group_2 = new WebSocket(`wss://dev-api.oneskyit.com/ws/group/${group_id_2}`);
// let ws_group_3 = new WebSocket(`wss://dev-api.oneskyit.com/ws/group/${group_id_3}`);

// // ws.onmessage = function(event) {
// //     console.log(event);
// //     message_list.push(event.data);
// //     message_list = message_list; // trigger Svelte update
// // };

// ws_client.onmessage = function(event) {
//     console.log(event);
//     message_list.push(event.data);
//     message_list = message_list; // trigger Svelte update
// };

// ws_group_1.onmessage = function(event) {
//     console.log(event);

//     let data = JSON.parse(event.data);
//     if (data.type == 'cmd') {
//         console.log('CMD');
//         cmd_list_grp_1.push(data);
//         cmd_list_grp_1 = cmd_list_grp_1; // trigger Svelte update
//     } else {
//         console.log('other');
//         ws_received_list_grp_1.push(data);
//         ws_received_list_grp_1 = ws_received_list_grp_1; // trigger Svelte update
//     }
// };

// ws_group_2.onmessage = function(event) {
//     console.log(event);
//     message_list_grp_2.push(event.data);
//     message_list_grp_2 = message_list_grp_2; // trigger Svelte update
// };

// ws_group_3.onmessage = function(event) {
//     console.log(event);
//     message_list_grp_3.push(event.data);
//     message_list_grp_3 = message_list_grp_3; // trigger Svelte update
// };

onMount(() => {
    console.log('** Element Mounted: ** Element Testing');
});

function handle_send_message(event) {
    console.log('*** handle_send_message() ***');

    console.log(client_id);
    // console.log(dm_client_id);

    console.log(message_text);

    let data = { 'client_id': client_id, 'type': type, 'msg': message_text };
    let data_json_str = JSON.stringify(data);

    if (type == 'group') {
        // data['group_id'] = '';
        // ws_group.send(data_json_str);
        if (group_id == 'test_grp_123') {
            ws_group_1.send(data_json_str);
        } else if (group_id == 'test_grp_999') {
            ws_group_2.send(data_json_str);
        } else if (group_id == 'test_grp_poster') {
            ws_group_3.send(data_json_str);
        } else {

        }
    } else if (type == 'cmd' && group_id) {
        if (group_id == 'test_grp_123') {
            ws_group_1.send(data_json_str);
        } else if (group_id == 'test_grp_999') {
            ws_group_2.send(data_json_str);
        } else if (group_id == 'test_grp_poster') {
            ws_group_3.send(data_json_str);
        } else {

        }
    } else {
        ws_client.send(data_json_str);
    }

    // if (dm_client_id) {
    //     data = { 'client_id': client_id, 'message': message_text };
    // } else {
    // }




    // if (dm_client_id) {
    //     ws_client.send(data_json_str);
    // } else {
    //     ws.send(data_json_str);
    // }

    message_text = '';
}

let message_text_example = null;


let ws_group_example_id_1 = 'example-group-id-111';
let ws_group_example_id_2 = 'example-group-id-222';

function ws_connect_group_id({group_id, client_id}) {
    let ws_connection = new WebSocket(`wss://dev-api.oneskyit.com/ws/group/${group_id}/client/${client_id}`);

    ws_connection.onopen = function() {
        console.log('WS connected');
        ws_connection.send(JSON.stringify({
            client_id: client_id,
            target: 'echo',
            type: 'hello',
            group_id: group_id,
            msg: 'Connected!'
        }));
    };

    ws_connection.onmessage = function(event) {
        console.log('WS message received');
        console.log(event);

        let data = JSON.parse(event.data);

        if (data.type == 'cmd') {
            console.log(`Type CMD: ${data.cmd}`);
            cmd_list_grp_1.push(data);
            cmd_list_grp_1 = cmd_list_grp_1; // trigger Svelte update
        } else {
            console.log('Type other');
            if (data.group_id == ws_group_example_id_1) {
                ws_received_list_grp_1.push(data);
                ws_received_list_grp_1 = ws_received_list_grp_1; // trigger Svelte update
            } else if (data.group_id == ws_group_example_id_2) {
                ws_received_list_grp_2.push(data);
                ws_received_list_grp_2 = ws_received_list_grp_2; // trigger Svelte update
            }
        }

        return 'what is this?';
    };

    ws_connection.onclose = function(event) {
        console.log('WS connection closed');
        setTimeout(function() {
            ws_connect_group_id({group_id: group_id, client_id: client_id});
        }, 1000);
    };

    // NOTE WARNING: Uncommenting this seems to break FastAPI somehow???
    // NOTE: from FastAPI log: RuntimeError: Unexpected ASGI message 'websocket.send', after sending 'websocket.close'.
    // ws_connection.onerror = function(err) {
    //     console.error('WS socket error: ', err.message, 'Closing socket');
    //     // ws_connection.close();
    // };

    return ws_connection;
}

// Start the WS function
let ws_group_example_1 = ws_connect_group_id({group_id: ws_group_example_id_1, client_id: client_id});
let ws_group_example_2 = ws_connect_group_id({group_id: ws_group_example_id_2, client_id: client_id});

// Send JSON formatted data using ws_group_example connection object inside ws_connect_group()
function handle_send_data_json_1(event) {
    console.log('*** handle_send_data_json_1() ***');

    let ws_data_json_str = JSON.stringify(ws_data);

    let response = ws_group_example_1.send(ws_data_json_str);
}

function handle_send_data_json_2(event) {
    console.log('*** handle_send_data_json_2() ***');

    let ws_data_json_str = JSON.stringify(ws_data);

    let response = ws_group_example_2.send(ws_data_json_str);
}




function handle_send_message_example(event) {
    console.log('*** handle_send_message_example() ***');

    console.log(client_id);
    // console.log(dm_client_id);

    console.log(message_text_example);

    type = 'cmd';

    // let data = { 'client_id': client_id, 'type': type, 'cmd': message_text_example };
    let data = { 'type': type, 'cmd': message_text_example };
    // let data = { 'client_id': client_id, 'type': type, 'msg': message_text_example };
    console.log(data);



    ws_group_example.send(data_json_str);

    // if (type == 'group') {
    //     if (group_id == 'test_grp_123') {
    //         ws_group_1.send(data_json_str);
    //     } else if (group_id == 'test_grp_999') {
    //         ws_group_2.send(data_json_str);
    //     } else if (group_id == 'test_grp_poster') {
    //         ws_group_3.send(data_json_str);
    //     } else {

    //     }
    // } else if (type == 'cmd' && group_id) {
    //     if (group_id == 'test_grp_123') {
    //         ws_group_1.send(data_json_str);
    //     } else if (group_id == 'test_grp_999') {
    //         ws_group_2.send(data_json_str);
    //     } else if (group_id == 'test_grp_poster') {
    //         ws_group_3.send(data_json_str);
    //     } else {

    //     }
    // } else {
    //     ws_client.send(data_json_str);
    // }

    message_text_example = '';
}
</script>


<section class="container testing_main">

<header>
    <h1>Testing Page</h1>
</header>

{#if 1==2}
<form on:submit|preventDefault={handle_send_message}>
    <select bind:value={ws_data.type}>
        <option value="">None</option>
        <option value="echo">Echo</option>
        <option value="dm">Direct Message</option>
        <option value="group">Group Message</option>
        <option value="all">Broadcast to All</option>
        <option value="cmd">Command</option>
    </select>

    <select bind:value={group_id}>
        <option value="">None</option>
        <option value="test_grp_123">123</option>
        <option value="test_grp_999">999</option>
        <option value="test_grp_poster">A Poster Group</option>
    </select>

    <!-- <input type="text" bind:value={dm_client_id} placeholder="Direct message client ID"/> -->

    <input type="text" bind:value={message_text} placeholder="Your message"/>

    <button>Send</button>
</form>
{/if}

{#if 1==2}
<form on:submit|preventDefault={handle_send_message_example}>
    <!-- <select bind:value={ws_data.type}>
        <option value="">None</option>
        <option value="echo">Echo</option>
        <option value="dm">Direct Message</option>
        <option value="group">Group Message</option>
        <option value="all">Broadcast to All</option>
        <option value="cmd">Command</option>
    </select> -->

    <select bind:value={group_id}>
        <option value="">None</option>
        <option value="test_grp_123">123</option>
        <option value="test_grp_999">999</option>
        <option value="test_grp_poster">A Poster Group</option>
    </select>

    <!-- <input type="text" bind:value={dm_client_id} placeholder="Direct message client ID"/> -->

    <input type="text" bind:value={message_text_example} placeholder="Your message"/>

    <button>Send Example Group</button>
</form>
{/if}


<form on:submit|preventDefault={handle_send_data_json_1}>
    Send some JSON!
    <div>
        Target:
        <select bind:value={ws_data.target}>
            <option value="">None</option>
            <option value="echo">Echo</option>
            <option value="dm">Direct Message</option>
            <option value="grp" selected>Group Message</option>
            <option value="all">Broadcast to All</option>
        </select>

        Type:
        <select bind:value={ws_data.type}>
            <option value="">None</option>
            <option value="cmd">Command</option>
            <option value="msg" selected>Message</option>
            <option value="json">JSON</option>
            <option value="status">Status</option>
            <option value="hello">Hello</option>
            <option value="bye">Goodbye</option>
        </select>
    </div>

    <div>Group ID (will be part of URL in this case)
    <!-- <select bind:value={group_id}>
        <option value="">None</option>
        <option value="test_grp_123">123</option>
        <option value="test_grp_999">999-AA-FF</option>
        <option value="test_grp_poster">A Poster Group</option>
    </select> -->
    </div>

    <!-- <input type="text" bind:value={dm_client_id} placeholder="Direct message client ID"/> -->

    <input type="text" bind:value={ws_data.msg} placeholder="Your message"/>

    <button>Send Example Group 1</button>
</form>

<h2>Messages</h2>

<!-- <ul class='messages'>
    {#each message_list as message_entry}
        <li>{message_entry.type} {message_entry.msg}</li>
    {/each}
</ul> -->

<!-- <hr> -->

<ul class='messages'>
    {#each ws_received_list_grp_1 as message_entry}
        <li>[{(message_entry.group_id||'No Group ID')}] {(message_entry.client_id||'No Client ID')} &mdash; {(message_entry.target||'No Target')} &mdash; {(message_entry.type||'No Type')}: {message_entry.msg}</li>
    {/each}
</ul>

<hr>

<form on:submit|preventDefault={handle_send_data_json_2}>
    Send some JSON!
    <div>
        Target:
        <select bind:value={ws_data.target}>
            <option value="">None</option>
            <option value="echo">Echo</option>
            <option value="dm">Direct Message</option>
            <option value="grp" selected>Group Message</option>
            <option value="all">Broadcast to All</option>
        </select>

        Type:
        <select bind:value={ws_data.type}>
            <option value="">None</option>
            <option value="cmd">Command</option>
            <option value="msg" selected>Message</option>
            <option value="json">JSON</option>
            <option value="status">Status</option>
            <option value="hello">Hello</option>
            <option value="bye">Goodbye</option>
        </select>
    </div>

    <div>Group ID (will be part of URL in this case)
    <!-- <select bind:value={group_id}>
        <option value="">None</option>
        <option value="test_grp_123">123</option>
        <option value="test_grp_999">999-AA-FF</option>
        <option value="test_grp_poster">A Poster Group</option>
    </select> -->
    </div>

    <!-- <input type="text" bind:value={dm_client_id} placeholder="Direct message client ID"/> -->

    <input type="text" bind:value={ws_data.msg} placeholder="Your message"/>

    <button>Send Example Group 2</button>
</form>

<h2>Messages</h2>

<ul class='messages'>
    {#each ws_received_list_grp_2 as message_entry}
        <li>[{(message_entry.group_id||'No Group ID')}] {(message_entry.client_id||'No Client ID')} &mdash; {(message_entry.target||'No Target')} &mdash; {(message_entry.type||'No Type')}: {message_entry.msg}</li>
    {/each}
</ul>


<hr>

<!-- <ul class='messages'>
    {#each message_list_grp_3 as message_entry}
        <li>{message_entry.type} {message_entry.msg}</li>
    {/each}
</ul> -->







<hr>

<h2>Commands</h2>

<ul class='commands'>
    {#each cmd_list_grp_1 as cmd_entry}
        <li>[{cmd_entry.type}] {cmd_entry.cmd} &mdash; {cmd_entry.msg}</li>
    {/each}
</ul>

</section>


<style>
</style>
