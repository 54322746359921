<script lang="ts">
// *** Import Svelte core
import { onMount } from 'svelte';

// *** Import Aether core variables and functions
import { ae, api, Element_modal_v3 } from 'aether_npm_lib';
import { client, cfg, page, slct, slct_trigger, ae_com } from '../stores';

// *** Import Aether core components
import Access_type_element from '../mods/element_access_type.svelte';

// *** Import Aether module variables and functions
import { get_person_obj } from './stores_person_api.js';

// *** Import Aether module components
import Person_id_edit_quick from './10_person_id_edit_quick.svelte';

// *** Export/Exposed variables and functions for component

// *** Set initial variables
// $slct.person_id = $page['page_for']['person'];
// $slct_trigger = 'person';

let person_obj_get_promise = null;
// let person_obj_update_promise = null;

// let person_data = null;

let show_disabled: boolean = false; // param enabled = 'enabled', 'disabled', 'all'
let show_hidden: boolean = false; // param hidden = 'hidden', 'not_hidden', 'all'

let show_modal_person_crud = false;
let redirect_to = '/order/cart/view';


onMount(() => {
    console.log('** Component Mounted: ** AE Person: Main Quick');

    if (!$slct.person_id) {
        $slct.person_id = null;
        $slct.person_obj = { 'contact': { 'address': {} } };
        show_modal_person_crud = true;

        // page.params.redirect_to = 'order/cart/view';
    }
});

$: if ($slct_trigger == 'person' && $slct.person_id) {
    console.log(`Selected Person: ${$slct.person_id}`);
    $slct_trigger = null;

    handle_load_person_obj({person_id: $slct.person_id, try_cache: false});
}

async function handle_load_person_obj({person_id=null, try_cache=true}){
    console.log('*** handle_load_person_obj() ***');
    // console.log($slct.person_id);

    if (person_id) {
        let params = { inc_address: true, inc_contact: true, inc_organization: true, enabled: 'enabled', hidden: 'not_hidden', limit: 200 };

        if (show_disabled) {
            params['enabled'] = 'all'
        }

        if (show_hidden) {
            params['hidden'] = 'all'
        }

        person_obj_get_promise = get_person_obj({ person_id: person_id, inc_address: true, inc_contact: true, inc_organization: true, params: params, log_lvl: 0 });

        person_obj_get_promise.then(function (person_obj_result) {
            if (!person_obj_result) {
                console.log('The result was null or false when trying to get the person.');
                return;
            }

            if (JSON.stringify($slct.person_obj) === JSON.stringify(person_obj_result)) {
                console.log('No change in person obj result.');
            } else {
                $slct.person_obj = person_obj_result;
            }
            // console.log($slct.person_obj);
        })
        .catch(function (error) {
            console.log(error);
            return false;
        });
    } else {
        return;
    }

    return person_obj_get_promise;
}

function handle_person_obj_created(person) {
    console.log('*** handle_person_obj_created() ***');

    if ($page.params.redirect_to) {
        redirect_to = $page.params.redirect_to;
    }

    show_modal_person_crud = false;

    if ($client.manager_access || $ae_com.manager_access) {
        if (confirm(`Person was created. Load person as browser client session. ${$slct.person_id}`)) {
            if (redirect_to) {
                window.location.href = `/person/load/${$slct.person_id}?redirect_to=${redirect_to}`;
            } else {
                window.location.href = `/person/load/${$slct.person_id}`;
            }
        }
    } else {
        // alert(`A new person record was created. ID: ${$slct.person_id}`);

        if (redirect_to) {
            window.location.href = `/person/load/${$slct.person_id}?redirect_to=${redirect_to}`;
        } else {
            window.location.href = `/person/load/${$slct.person_id}`;
        }
    }

    // handle_load_person_obj({person_id: $slct.person_id});

    return true;
}

function handle_person_obj_deleted(person) {
    console.log('*** handle_person_obj_deleted() ***');

    show_modal_person_crud = false;

    // if (confirm('Person was deleted. Refresh this person page.')) {
    //     window.location.href = `/person/${$slct.person_id}`;
    // } else {
    //     handle_load_person_obj({person_id: $slct.person_id});
    // }

    // return true;
}

function handle_person_obj_updated(person) {
    console.log('*** handle_person_obj_updated() ***');

    show_modal_person_crud = false;

    // NOTE: This is may need to be refined. Just because a redirect_to was or was not passed does not mean the person load ID should or should not be run. -2022-12-01
    if ($page.params.redirect_to) {
        // window.location.href = $page.params.redirect_to;
        window.location.href = `/person/load/${$slct.person_id}?redirect_to=${$page.params.redirect_to}`;
    } else {
        handle_load_person_obj({person_id: $slct.person_id});
    }

    return true;
}
</script>


<section id="person_id_quick" class="person_id_quick">

{#await person_obj_get_promise}
    Loading initial data...
{:then}

{#if $slct.person_id && $slct.person_obj}
    {#if redirect_to}
        <a href="url_for order.order_cart_view redirect_to=redirect_to)" class="ae_btn btn_outline_primary float-end"><span class="fas fa-shopping-cart"></span> Back to Cart</a>
    {/if}
    <header>
        <h1>Person: <span id="obj__person--header" class="obj__person--header">{$slct.person_obj.display_name}</span></h1>
    </header>

    {#if $client.administrator_access || $ae_com.administrator_access}
        <div id="person__id_random--cont" class="person__id_random--cont" class:d_none={!$slct.person_obj.person_id_random}>ID: <span id="person__id_random">{$slct.person_obj.person_id_random}</span></div>
    {/if}

    <!-- <button id="btn_create_update_person" type="button" data-bs-toggle="modal" data-bs-target="#create_update_person" data-bs-keyboard="true" class="ae_btn btn_outline_primary m-1"><span class="fas fa-user-cog"></span> Update Personal Information</button> -->

    <button
        on:click={() => {
            show_modal_person_crud = true;
        }}
        class="ae_btn btn_lg btn_outline_primary m-1"
        title="Update personal information"
        >
        <span class="fas fa-user-cog"></span> Update Personal Information
    </button>

    <hr>

    <!-- {#if not session.logged_in and session.person.id_random} -->
    <div style="font-size: .8rem;">
        <div>
            <a href="{$page.current_url_root}person/load/{$slct.person_id}" class="ae_btn btn_sm">
                <span class="fas fa-link"></span> Personal Link:
            </a>
            <span>{$page.current_url_root}person/load/{$slct.person_id}</span>
        </div>
        <div>
            You may use this link later to reload this personal profile along with the order history.
        </div>
    </div>
    <!-- {/if} -->

{:else if $slct.person_id}
    <p>Person data has not loaded yet.</p>
{:else}
    <button
    on:click={() => {
        $slct.person_id = null;
        $slct.person_obj = { 'contact': { 'address': {} } };
        show_modal_person_crud = true;
    }}
    class="ae_btn btn_outline_primary m-1"
    title="Update personal information"
    >
    <span class="fas fa-user-edit"></span> Add Your Information
    </button>
{/if}

{/await}

</section>


{#if show_modal_person_crud}
<Element_modal_v3
    show = { true }
    modal_cover_body = { true }
    on:close={() => {
        show_modal_person_crud = false;

        // $slct.person_id = null;
        // $slct.person_obj = {};
    }}
    >

    <span slot="header_title">
        {#if $slct.person_id}
            Edit Person
        {:else}
            Add Your Information
        {/if}
    </span>
    <span slot="body">

        <Person_id_edit_quick
            on:person_obj_created={handle_person_obj_created}
            on:person_obj_deleted={handle_person_obj_deleted}
            on:person_obj_updated={handle_person_obj_updated}
            />

    </span>

</Element_modal_v3>
{/if}

<style>
</style>
