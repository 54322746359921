<script lang="ts">
import { onMount } from 'svelte';

import { Element_obj_tbl_row, Element_sql_qry } from 'aether_npm_lib';

import { account_id, cfg, client, page } from '../stores';
import { slct_obj, slct_obj_id, slct_obj_li, slct_obj_li_type, slct_obj_type } from './stores_admin.js';
import { get_obj_li, load_obj, update_obj_prop } from './stores_admin_api.js';

// import Element_sql_qry from '../mods/element_sql_qry.svelte';
// import Element_obj_tbl_row from '../mods/element_obj_tbl_row.svelte';
import Admin_obj_view from './10_admin_obj_view.svelte';

let allow_access = false;

let show_dynamic_admin_table = false;
let show_sql_qry = false;

let obj_load_promise;
let obj_get_li_promise;


$: if ($slct_obj_li_type) {
    obj_get_li_promise = handle_get_obj_li($slct_obj_li_type, 'account', $account_id);
}

$: if ($slct_obj_type && $slct_obj_id) {
    console.log(`Selected Object Type: ${$slct_obj_type} Object ID: ${$slct_obj_id}`)
    obj_load_promise = handle_load_obj($slct_obj_type, $slct_obj_id);
}

/* BEGIN: Lifecycle functions (onMount, beforeUpdate, afterUpdate,
onDestroy, tick) */
onMount(async () => {
    console.log('Admin Main component has mounted');

    if ($client.administrator_access) {
        allow_access = true;
    } else {
    }

    obj_get_li_promise = handle_get_obj_li('person', 'account', $account_id);

});
/* END: Lifecycle functions (onMount, beforeUpdate, afterUpdate,
onDestroy, tick) */

/* BEGIN: Handle requests (archive, create, hide, remove, select, update, POST, PATCH, GET, DELETE) */
async function handle_load_obj(obj_type, obj_id) {
    console.log('*** handle_load_obj() ***');

    let params = { }
    obj_load_promise = await load_obj({obj_type:obj_type, obj_id:obj_id, params: params, log_lvl: 2});

    if (obj_load_promise) {
        $slct_obj = obj_load_promise;
        console.log($slct_obj);
        return;
    } else {
        console.log('No results when looking up the object type and ID.')
        return null;
    }
}

async function handle_get_obj_li(obj_type, for_obj_type, for_obj_id) {
    console.log('*** handle_get_obj_li() ***');

    let params = { }
    obj_get_li_promise = await get_obj_li({obj_type:obj_type, for_obj_type:for_obj_type, for_obj_id:for_obj_id, inc_x:false,  params: params, log_lvl: 2});

    if (obj_get_li_promise) {
        $slct_obj_li_type = obj_type
        $slct_obj_li = obj_get_li_promise;
        console.log($slct_obj_li);
        return;
    } else {
        console.log('No results when looking up the object type and ID.')
        return null;
    }
}
/* END: Handle requests (archive, create, hide, remove, select, update, POST, PATCH, GET, DELETE) */

/* BEGIN: Handle other local actions (show/hide form, process data) */
/* END: Handle other local actions (show/hide form, process data) */

/* BEGIN: Handle children events (archived, canceled, closed, created, deleted, hidden, updated) */
/* END: Handle children events (archived, canceled, closed, created, deleted, hidden, updated) */
</script>


<section class="component obj_type_main admin_main container">
<header>
    <h2>Main: Admin</h2>
</header>

<button on:click={() => {
    show_dynamic_admin_table = !show_dynamic_admin_table;
    }}
    >Show/Hide Dynamic Admin Data Table
</button>
<button on:click={() => {
    show_sql_qry = !show_sql_qry;
    }}
    >Show/Hide SQL Query
</button>

{#if show_dynamic_admin_table}
    {#if $slct_obj_li}
        <table class="table table-bordered table-striped table-hover">
            <Element_obj_tbl_row row_header={true} obj_li_type={$slct_obj_li_type} obj={$slct_obj_li[0]} />
            <!-- <tr>
            {#each Object.entries($slct_obj_li[0]) as [obj_prop_name, value]}
                <th>{obj_prop_name}</th>
            {/each}
            </tr> -->
            {#each $slct_obj_li as slct_obj}
                <!-- <Element_obj_tbl_row obj_li_type={$slct_obj_li_type} obj={slct_obj} /> -->
                <!-- <tr>
                {#each Object.entries(slct_obj) as [obj_prop_name, value]}
                    <td data-obj_type={$slct_obj_li_type} data-obj_prop_name={obj_prop_name}>{value}</td>
                {/each}
                </tr> -->
                <!-- <div>{slct_obj}</div> -->
            {/each}
        </table>
    {:else}
        <div>Nothing to show yet...</div>
    {/if}

    {#if $slct_obj_type && $slct_obj_id && $slct_obj}
        <Admin_obj_view obj={$slct_obj} />
    {:else}
        <div>Nothing to show yet...</div>
    {/if}
{/if}

<hr />


{#if show_sql_qry}
    <Element_sql_qry api_cfg={$cfg.api} sql_statement={`SELECT * FROM user WHERE user.account_id = 1`} />
{/if}

</section>


<style>
table {
    font-size: smaller;
}
</style>