<script lang="ts">
// *** Import Svelte core
import { createEventDispatcher, onMount } from 'svelte';

// *** Import Aether core variables and functions
import { ae, Element_input, Element_person_name } from 'aether_npm_lib';
import { account_id, client, slct, slct_trigger, ae_com } from '../stores';

import { slct_person_id, slct_person_obj } from '../person/stores_person.js';
import { load_person_obj, update_person_obj, create_person_obj } from '../person/stores_person_api.js';
// import { slct_user_id, slct_user_obj } from '../user/stores_user.js';

// *** Import Aether core components

// *** Import Aether module variables and functions

// *** Import Aether module components

// *** Export/Exposed variables and functions for component
export let auth_key: string = null; //  If passed then need to look up the person_id along with the auth_key
export let person_id: string = null; // If only ID then need to look up
export let person_obj: object = { full_name:'', contact:{ address:{} }, user:{} }; // If object then use, no look up
// export let person_data: object = {}; // If only data then use as new default values
// export let person_user_id: string = null;

// *** Set initial variables
let person_name = { informal_name:'', given_name:'', middle_name:'', family_name:'', display_name:'', full_name:'', informal_full_name:'' };
// let contact_data: object = {};
// let address_data: object = {};

let person_obj_load_promise = null;
let person_obj_update_promise = null;
let person_obj_create_promise = null;

const dispatch = createEventDispatcher();


onMount(() => {
    console.log('Person User Form component has mounted');
    console.log(person_id);
    console.log(person_obj);

    if (person_id) {
        console.log(person_id);
        handle_load_person_obj();
    }
});

async function handle_load_person_obj() {
    console.log('*** handle_load_person_obj() ***');

    $slct_person_id = person_id;

    let params = { inc_address: true, inc_contact: true, inc_user: true };
    person_obj_load_promise = await load_person_obj({person_id:person_id, auth_key:auth_key, params: params, log_lvl: 1});
    person_obj = person_obj_load_promise;
    $slct_person_obj = person_obj_load_promise;
    // $slct_user_obj = person_obj_load_promise.user;

    person_name = {
        informal_name: person_obj.informal_name,
        given_name: person_obj.given_name,
        middle_name: person_obj.middle_name,
        family_name: person_obj.family_name,
        display_name: person_obj.display_name,
        full_name: person_obj.full_name,
        informal_full_name: person_obj.informal_full_name
    }
    console.log(person_name);
}

function handle_canceled_form() {
    console.log('*** handle_canceled_form() ***');

    dispatch('person_form_canceled', {
    });
}

async function handle_submit_form(event) {
    console.log('*** handle_submit_form() ***');

    let form_data = new FormData(event.target);
    console.log(form_data);

    let find_obj_type = '';

    find_obj_type = 'person__';
    let person_data = ae.util.extract_prefixed_form_data({prefix:find_obj_type, form_data: form_data, log_lvl: 0});

    find_obj_type = 'user__';
    let user_data = ae.util.extract_prefixed_form_data({prefix:find_obj_type, form_data: form_data, log_lvl: 1});

    find_obj_type = 'contact__';
    let contact_data = ae.util.extract_prefixed_form_data({prefix:find_obj_type, form_data: form_data, log_lvl: 0});

    find_obj_type = 'address__';
    let address_data = ae.util.extract_prefixed_form_data({prefix:find_obj_type, form_data: form_data, log_lvl: 0});

    person_data['user'] = user_data;
    contact_data['address'] = address_data;
    person_data['contact'] = contact_data;

    // Join with the raw form data with person_name. person_name is a select list of the processed name parts from Svelte element_person_name and JS process_person_name
    person_data = Object.assign(person_data, person_name);

    console.log(person_data);

    if (person_id) {
        person_obj_update_promise = await update_person_obj({person_id:person_id, data:person_data, return_obj:true, log_lvl: 2});
        person_obj = person_obj_update_promise;
        console.log(person_obj);
        console.log(`Person updated. Person ID: ${person_id}`);
        dispatch('person_user_updated', {
            person_obj: person_obj,
            // person_id: person_obj.person_id_random,
            // contact_id: person_obj.contact.contact_id_random,
            // address_id: person_obj.contact.address.address_id_random,
            // user_id: person_obj.user_id_random,
            // username: person_obj.user.username,
        });
    } else {
        person_data['account_id_random'] = $account_id;

        person_obj_create_promise = await create_person_obj({account_id: $account_id, data:person_data, return_obj:true, log_lvl: 2});
        person_obj = person_obj_create_promise;
        person_id = person_obj_create_promise.person_id_random;
        console.log(`Person created. Person ID: ${person_id}`);
        dispatch('person_user_created', {
            person_obj: person_obj,
        });
    }

}

function handle_person_name_change(event) {
    console.log('*** handle_person_name_change() ***');
    person_name = event.detail.person_name;
    console.log(person_name);
}

function handle_oninput_contact_email(event) {
    console.log('*** handle_oninput_contact_email() ***');
    console.log(event);
    // let email = event.detail.contact__email;
    let email = event.detail.value;
    person_obj.user.email = email;
}
</script>


<section class="component person_user_form">
{#await person_obj_load_promise}
    <div>Loading...</div>
{:then result}
    {#if !person_obj && auth_key}
        <div>The person ID and authorization key combination was not found or was not allowed.</div>
    {/if}
{:catch error}
    error
    <div class="error">{error.message}</div>
{/await}

{#await person_obj_create_promise}
    <div>Creating...</div>
{:then result}
    <!-- Done -->
{:catch error}
    <div class="error">{error.message}</div>
{/await}

{#await person_obj_update_promise}
    <div>Updating...</div>
{:then result}
    <!-- Done -->
{:catch error}
    <div class="error">{error.message}</div>
{/await}

{#if person_obj}
    <header>
        <h3>Welcome {person_obj.display_name}</h3>
    </header>
    <form on:submit|preventDefault={handle_submit_form} class="form-horizontal" on:keydown={e => e.key === 'Escape' && handle_canceled_form}>
        <Element_person_name {person_name} on:person_name_change={handle_person_name_change} />

        <!-- <Element_input {...ae.input_template.person.id_random} value={person_obj.person_id_random} use_name_prefix={true} /> -->
        <section class="person_obj mb-2">
            <Element_input {...ae.input_template.person.professional_title} value={person_obj.professional_title} use_name_prefix={true} content_layout={'floating_input'} container_class_li={['mb-1']} />
            <Element_input {...ae.input_template.person.affiliations} value={person_obj.affiliations} use_name_prefix={true} content_layout={'floating_input'} container_class_li={['mb-1']} />
            <Element_input {...ae.input_template.person.tagline} value={person_obj.tagline} use_name_prefix={true} content_layout={'floating_input'} container_class_li={['mb-1']} />
            <Element_input {...ae.input_template.person.notes} value={person_obj.notes} use_name_prefix={true} content_layout={'floating_input'} container_class_li={['mb-1']} />
        </section>

        <section class="user_obj mb-2">
            <fieldset>
                <legend>User</legend>
                <Element_input {...ae.input_template.user.id_random} value={person_obj.user.user_id_random} use_name_prefix={true} content_layout={'floating_input'} />

                {#if $client.manager_access || $ae_com.manager_access}
                    <Element_input {...ae.input_template.user.name} value={person_obj.user.name} use_name_prefix={true} content_layout={'floating_input'} container_class_li={['mb-1', 'col-md-8', 'col-lg-6']} />
                    <Element_input {...ae.input_template.user.email} bind:value={person_obj.user.email} use_name_prefix={true} content_layout={'floating_input'} container_class_li={['mb-1', 'col-md-8', 'col-lg-6']} />
                    <Element_input {...ae.input_template.user.username} value={person_obj.user.username} use_name_prefix={true} content_layout={'floating_input'} container_class_li={['mb-1', 'col-md-8', 'col-lg-6']} />
                {:else}
                    <Element_input {...ae.input_template.user.name} value={person_obj.user.name} use_name_prefix={true} type="hidden" container_class_li={['mb-1', 'col-md-8', 'col-lg-6']} />
                    <Element_input {...ae.input_template.user.email} value={person_obj.user.email} use_name_prefix={true} type="hidden" container_class_li={['mb-1', 'col-md-8', 'col-lg-6']} />
                    <Element_input {...ae.input_template.user.username} value={person_obj.user.username} use_name_prefix={true} content_layout={'floating_input'} container_class_li={['mb-1', 'col-md-8', 'col-lg-6']} readonly />
                {/if}
            </fieldset>
        </section>

        <section class="person_obj contact_obj mb-2">
        <fieldset>
            <legend>Contact</legend>
            <Element_input {...ae.input_template.contact.id_random} value={person_obj.contact.contact_id_random} use_name_prefix={true} content_layout={'floating_input'} />

            <Element_input {...ae.input_template.contact.email} value={person_obj.contact.email} use_name_prefix={true} required content_layout={'floating_input'} container_class_li={['mb-1', 'col-md-8', 'col-lg-6']} on:oninput={handle_oninput_contact_email} />

            <div class="row">
                <Element_input {...ae.input_template.contact.phone_mobile} value={person_obj.contact.phone_mobile} use_name_prefix={true} content_layout={'floating_input'} container_class_li={['mb-1', 'col-md-6', 'col-lg-4']} />
                <Element_input {...ae.input_template.contact.phone_home} value={person_obj.contact.phone_home} use_name_prefix={true} content_layout={'floating_input'} container_class_li={['mb-1', 'col-md-6', 'col-lg-4']} />
                <Element_input {...ae.input_template.contact.phone_office} value={person_obj.contact.phone_office} use_name_prefix={true} content_layout={'floating_input'} container_class_li={['mb-1', 'col-md-6', 'col-lg-4']} />
            </div>
        </fieldset>
        </section>

        <section class="person_obj contact_obj address_obj mb-2">
        <fieldset>
            <legend>Address</legend>
            <Element_input {...ae.input_template.address.id_random} value={person_obj.contact.address.address_id_random} use_name_prefix={true} content_layout={'floating_input'} />

            <Element_input {...ae.input_template.address.name} value={person_obj.contact.address.name} use_name_prefix={true} content_layout={'floating_input'} container_class_li={['mb-1']} />

            <Element_input {...ae.input_template.address.line_1} value={person_obj.contact.address.line_1} use_name_prefix={true} content_layout={'floating_input'} container_class_li={['mb-1']} />
            <Element_input {...ae.input_template.address.line_2} value={person_obj.contact.address.line_2} use_name_prefix={true} content_layout={'floating_input'} container_class_li={['mb-1']} />
            <Element_input {...ae.input_template.address.line_3} value={person_obj.contact.address.line_3} use_name_prefix={true} content_layout={'floating_input'} container_class_li={['mb-1']} />
            <div class="row">
                <Element_input {...ae.input_template.address.city} value={person_obj.contact.address.city} use_name_prefix={true} required content_layout={'floating_input'} container_class_li={['mb-1', 'col-md-4', 'col-lg-4']} />
                <Element_input {...ae.input_template.address.country_subdivision_code} select_option_none={true} value={person_obj.contact.address.country_subdivision_code} use_name_prefix={true} content_layout={'floating_input'} container_class_li={['mb-1', 'col-md-4', 'col-lg-4']} />
                <Element_input {...ae.input_template.address.postal_code} value={person_obj.contact.address.postal_code} use_name_prefix={true} required content_layout={'floating_input'} container_class_li={['mb-1', 'col-md-4', 'col-lg-4']} />
            </div>
            <Element_input {...ae.input_template.address.country_alpha_2_code} select_option_none={true} value={person_obj.contact.address.country_alpha_2_code} use_name_prefix={true} content_layout={'floating_input'} container_class_li={['mb-1', 'col-md-6', 'col-lg-6']} />
        </fieldset>
        </section>

        <div class="edit_options">
            <button class="ae_btn btn_outline_default person-cancel" on:click={handle_canceled_form} type="button">
                Cancel
            </button>
            <button class="ae_btn btn_outline_success person-edit" type="submit">
                Save Changes
            </button>
        </div>
    </form>
<!-- {:else} -->
    <!-- <div>Nothing loaded yet</div>
    <button on:click={handle_load_person_obj()}>
        Load person
    </button> -->
{/if}
</section> <!-- END section person_container_form -->


<style>
</style>
