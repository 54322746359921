<script lang="ts">
import { createEventDispatcher, onMount, tick } from 'svelte';

import { ae_com, client } from '../stores';

let entered_passcode = null;
let show_passcode_input: boolean = false;

let trigger = null;

const dispatch = createEventDispatcher();
// window.localStorage.setItem('test_value', 'Changing local storage!!! The Access Type is loading...!!!');
// window.sessionStorage.setItem('test_value', 'Changing session storage!!! The Access Type is loading...');

window.addEventListener('message', function(event) {
    console.log("Message received from another window: " + event.data); // Message received from child

    if (event.data) {
        console.log(event.data);

    }
});

onMount(() => {
    console.log('** Element Mounted: ** Element Access Type');

    // // Save the passcode so they will not need to enter it again on the tracking page.
    // let client_access_type_value = window.localStorage.getItem('access_type');

    // let access_checks_results =  $client.process_permission_checks(client_access_type_value);

    // $ae_com = {...$ae_com, ...access_checks_results};

    window.localStorage.setItem('test_value', 'Change local storage!!! The Access Type loaded local!!!');
    window.sessionStorage.setItem('test_value', 'Change session storage!!! The Access Type is loaded session!!!');
});


$: if (entered_passcode && entered_passcode.length >= 5) {
    console.log(`entered_passcode=${entered_passcode}`);
    handle_check_access_type_passcode();
}

$: if (trigger && $ae_com.access_type) {
    console.log(`$ae_com.access_type=${$ae_com.access_type}`);

    let access_checks_results =  $client.process_permission_checks($ae_com.access_type);

    $ae_com = {...$ae_com, ...access_checks_results};
    ae_bridge.ae_com = $ae_com;

    // console.log($ae_com);
} else if (trigger) {
    console.log(`$ae_com.access_type=not set`);

    let access_checks_results =  $client.process_permission_checks($client.access_type);

    $ae_com = {...$ae_com, ...access_checks_results};
    ae_bridge.ae_com = $ae_com;

    // console.log($ae_com);
}

function handle_check_access_type_passcode() {
    // console.log('*** handle_check_access_type_passcode() ***');

    if (entered_passcode && entered_passcode.length >= 5) {
        if ($client.administrator_passcode == entered_passcode) {
            console.log('Administrator passcode matched');

            window.localStorage.setItem('access_type', 'administrator');

            entered_passcode = null;

            $ae_com.access_type = 'administrator';
            trigger = 'process_permission_check';

            // $ae_com = $ae_com; // Trigger Svelte just in case
            // ae_com.set($ae_com);
            // console.log($ae_com);

            dispatch_access_type_changed();

            return true;
        } else if ($client.trusted_passcode == entered_passcode) {
            console.log('Trusted passcode matched');

            window.localStorage.setItem('access_type', 'trusted');

            entered_passcode = null;

            $ae_com.access_type = 'trusted';
            trigger = 'process_permission_check';

            // $ae_com = $ae_com; // Trigger Svelte just in case
            // ae_com.set($ae_com);
            // console.log($ae_com);

            dispatch_access_type_changed();

            return true;
        } else if ($client.authenticated_passcode == entered_passcode) {
            console.log('Authenticated passcode matched');

            window.localStorage.setItem('access_type', 'authenticated');

            entered_passcode = null;

            $ae_com.access_type = 'authenticated';
            trigger = 'process_permission_check';

            dispatch_access_type_changed();

            return true;
        } else {
            console.log('Passcode does not match');

            window.localStorage.setItem('access_type', 'anonymous');

            $ae_com.access_type = 'anonymous';
            trigger = 'process_permission_check';

            // $ae_com = $ae_com; // Trigger Svelte just in case
            // ae_com.set($ae_com);
            // console.log($ae_com);

            dispatch_access_type_changed();

            return false;
        }
    } else {
        console.log('Entered passcode too short.');

        // $ae_com.access_type = null; // 'anonymous';

        // dispatch_access_type_changed()

        return null;
    }
}

function handle_clear_access() {
    // NOTE: I think it makes since to reset this to anonymous even if logged in as an admin or similar.
    window.localStorage.setItem('access_type', 'anonymous');

    // $ae_com.access_type = null; // 'anonymous';
    $ae_com.access_type = 'anonymous';
    trigger = 'process_permission_check';

    show_passcode_input = false;

    // $ae_com = $ae_com; // Trigger Svelte just in case
    // ae_com.set($ae_com);
    // console.log($ae_com);

    dispatch_access_type_changed();

    return true;
}

function dispatch_access_type_changed() {
    console.log('*** dispatch_access_type_changed() ***');

    console.log($client);
    console.log($ae_com);

    dispatch('access_type_changed', {
        access_type: $ae_com.access_type
    });

    // ae_bridge.access_type = $ae_com.access_type;

    // ae_bridge.ae_com = $ae_com;
}
</script>


<section id="Access-Type" class="access_type hidden-print">
    {#if $ae_com.access_type && $ae_com.access_type != 'anonymous'}
        {#if $ae_com.access_type == 'super'}
            <span class="fas fa-unlock"></span> Super Access
        {:else if $ae_com.access_type == 'manager'}
            <span class="fas fa-unlock"></span> Manager Access
        {:else if $ae_com.access_type == 'administrator'}
            <span class="fas fa-unlock"></span> Administrator Access
        {:else if $ae_com.access_type == 'trusted'}
            <span class="fas fa-unlock"></span> Trusted Access
        {:else if $ae_com.access_type == 'authenticated'}
            <span class="fas fa-unlock"></span> Authenticated Access
        {:else if $ae_com.access_type == 'anonymous'}
            <span class="fas fa-unlock"></span> Anonymous Access
        {:else}
            <span class="fas fa-unlock"></span> Unknown Access
        {/if}

        <button
            class="ae_btn btn_sm access_type_lock_btn"
            on:click={() => {
                handle_clear_access();
            }}
            title="Access mode is currently enabled/unlocked. Click to exit and lock."
            >
            <span class="fas fa-lock"></span> Lock
        </button>
    {:else}
        <button
            class="ae_btn btn_sm access_type_unlock_btn"
            on:click={async () => {
                show_passcode_input = !show_passcode_input;
                await tick();
                document.getElementById('access_passcode_input').focus();
                // element.focus({preventScroll:false});
            }}
            title="Anonymous public access is currently set. Access mode is disabled/locked."
            >
            <span class="fas fa-lock"></span>
            <span class="unlock_text">Unlock?</span>
        </button>
        <!-- <span class="fas fa-lock"></span> Locked -->

        <input
            id="access_passcode_input"
            bind:value={entered_passcode}
            class:d_none={!show_passcode_input}
            type="text"
            placeholder="Access Passcode"
            />
    {/if}
</section>


<style>
.access_type_unlock_btn .unlock_text {
    display: none;
}

.access_type_unlock_btn:hover .unlock_text {
    display: initial;
    /* outline: solid thin red; */
}
</style>
