<script lang="ts">
import { onMount } from 'svelte';

import { ae, Element_modal_v3, Element_input } from 'aether_npm_lib';

import { slct_obj, slct_obj_id, slct_obj_li, slct_obj_li_type, slct_obj_type } from './stores_admin.js';

// export let row_header: boolean = false;
// export let obj_li_type: object = null; // account, person, user, event, event_session, membership_person
export let obj = null;

let edit_obj = false;

/* BEGIN: Lifecycle functions (onMount, beforeUpdate, afterUpdate,
onDestroy, tick) */
onMount(async () => {
    console.log('Admin Object Table Row component has mounted');
});
/* END: Lifecycle functions (onMount, beforeUpdate, afterUpdate,
onDestroy, tick) */

/* BEGIN: Handle requests (archive, create, hide, remove, select, update, POST, PATCH, GET, DELETE) */
/* END: Handle requests (archive, create, hide, remove, select, update, POST, PATCH, GET, DELETE) */

/* BEGIN: Handle other local actions (show/hide form, process data) */
/* END: Handle other local actions (show/hide form, process data) */

/* BEGIN: Handle children events (archived, canceled, closed, created, deleted, hidden, updated) */
function handle_oninput(event) {
    console.log('*** handle_form_oninput() ***');
    console.log(event.detail.name);
    console.log(event.detail.value);
}
/* END: Handle children events (archived, canceled, closed, created, deleted, hidden, updated) */
</script>


<!-- NOTE: Set edit_obj to false on close. If I don't then I get an undefined error. -->
<Element_modal_v3
    show = { true }
    modal_cover_body = { true }
    on:close={() => {edit_obj = false; $slct_obj_type = null; $slct_obj_id = null; $slct_obj = null;}}>
    <span slot="header_title">
        Type: {$slct_obj_type} ID: {$slct_obj_id}

        {#if !edit_obj}
            <button on:click={() => edit_obj=true} class="ae_btn btn_warning"><span class="fas fa-sync"></span> Edit</button>
        {:else}
            <button on:click={() => edit_obj=false} class="ae_btn btn_primary"><span class="fas fa-sync"></span> View</button>
        {/if}
    </span>

    <!-- <span slot="header_text">
    </span> -->

    <span slot="body">

        {#each Object.entries(obj) as [obj_prop_name, obj_prop_value]}
            {#if !edit_obj}
                <div>{obj_prop_name}: {obj_prop_value}</div>
            {:else}
                <Element_input {...ae.input_template[$slct_obj_type][obj_prop_name]} use_name_prefix={true} value={obj_prop_value} content_layout={'floating_input'} container_class_li={['mb-1']} on:oninput={handle_oninput} />
            {/if}
        {/each}

    </span>

    <span slot="footer_text">
        <div class="load_datetime"><span class="label">Loaded:</span> <span class="datetime">{new Date()}</span></div>
    </span>
</Element_modal_v3>


<style>
</style>