<script lang="ts">
import { onMount } from 'svelte';

import { client, ae_com} from '../stores';

export let nav_menu_template: Object = null;


onMount(() => {
    console.log('** Element Mounted: ** Menu Main Navigation');

    document.getElementById('Main-Nav-Menu').classList.remove('no_nav_menu_content');

    if (nav_menu_template) {
        console.log('Nav Menu Template passed');
    } else {
        console.log('Nav Menu Template not passed');

        // nav_menu_template = $ae_com.main_nav_menu;

    //     nav_menu_template = {};
    //     // nav_menu_template = [];

    //     // Common navigation group names: debug, edit, help, navigation, test, warning
    //     // Common permissions (roles): '', administrator, authenticated, manager, public, staff, super
    //     // Common status: '', active, inactive, disabled

    //     // Navigation button group
    //     nav_menu_template['navigation'] = []; // group

    //     let nav_primary_btn = {}
    //     nav_primary_btn['id'] = null;
    //     nav_primary_btn['permission_li'] = '';
    //     nav_primary_btn['label'] = '<span class="fas fa-home"></span> Main';
    //     nav_primary_btn['href'] = '/';
    //     nav_primary_btn['status'] = '';
    //     nav_primary_btn['class_li'] = ['ae_btn', 'btn_sm', 'btn_primary'];
    //     nav_menu_template['navigation'].push(nav_primary_btn);

    //     // Admin button group
    //     nav_menu_template['admin'] = []; // group

    //     let nav_example_btn = {}
    //     nav_example_btn['id'] = null;
    //     nav_example_btn['class_li'] = ['ae_btn', 'btn_sm', 'btn_default'];
    //     nav_example_btn['label'] = 'Example';
    //     nav_example_btn['href'] = '/example';
    //     nav_example_btn['status'] = '';
    //     nav_example_btn['permission_li'] = 'super';
    //     nav_example_btn['confirm'] = 'Are you sure you want to click this button?';
    //     nav_menu_template['admin'].push(nav_example_btn);

    //     // Help button group
    //     nav_menu_template['help'] = []; // group

    //     let nav_help_btn = {}
    //     nav_help_btn['id'] = null;
    //     nav_help_btn['class_li'] = ['ae_btn', 'btn_sm', 'btn_info'];
    //     nav_help_btn['label'] = '<span class="fas fa-question"></span> Help';
    //     nav_help_btn['href'] = '/help';
    //     nav_help_btn['status'] = ''; // active, inactive, disabled
    //     nav_help_btn['permission_li'] = '';
    //     nav_menu_template['help'].push(nav_help_btn);
    }

    // console.log(nav_menu_template);
    // console.log($client.administrator_access);
});

$: if ($ae_com.main_nav_menu) {
    console.log('AE Common - Main (Core) Nav Menu:', $ae_com.main_nav_menu);
    nav_menu_template = $ae_com.main_nav_menu;
}
</script>


<!-- <nav class="main_menu secondary_menu btn_toolbar"> -->
    {#if nav_menu_template}
    {#each Object.entries(nav_menu_template) as [key, nav_menu_group_item_list] }
        <div class="btn_group {key}" role="group" aria-label="{key} group">
            {#each nav_menu_group_item_list as group_item, index2 }
                {#if
                    group_item.permission_li.length == 0
                    ||
                    ($client.super_access && group_item.permission_li.includes('super'))
                    ||
                    ($client.manager_access && group_item.permission_li.includes('manager'))
                    ||
                    (($client.administrator_access || $ae_com.administrator_access) && group_item.permission_li.includes('administrator'))
                    ||
                    (($client.trusted_access || $ae_com.trusted_access) && group_item.permission_li.includes('trusted'))
                    ||
                    ($client.logged_in_access && group_item.permission_li.includes('logged_in'))
                    ||
                    ($client.authenticated_access && group_item.permission_li.includes('authenticated'))
                    }
                    {#if group_item.type == 'button'}
                        {#if group_item.callback}
                            <button
                                class={group_item.class_li.join(' ')}
                                title={group_item.title}
                                on:click={group_item.callback(group_item)}
                                >
                                {@html group_item.label}
                            </button>
                        {:else if group_item.confirm}
                            <button
                                class={group_item.class_li.join(' ')}
                                title={group_item.title}
                                onclick="return confirm('{group_item.confirm}');"
                                >
                                {@html group_item.label}
                            </button>
                        {:else}
                            <button
                                class={group_item.class_li.join(' ')}
                                title={group_item.title}
                                >
                                {@html group_item.label}
                            </button>
                        {/if}
                    {:else}
                        {#if group_item.confirm}
                            <a
                                href="{group_item.href}"
                                class={group_item.class_li.join(' ')}
                                title={group_item.title}
                                onclick="return confirm('{group_item.confirm}');"
                                >
                                {@html group_item.label}

                                <!-- {#if group_item.permission_li}
                                    {group_item.permission_li}
                                {/if} -->
                            </a>
                        {:else}
                            <a
                                href="{group_item.href}"
                                class={group_item.class_li.join(' ')}
                                title={group_item.title}
                                >
                                {@html group_item.label}

                                <!-- {#if group_item.permission_li}
                                    {group_item.permission_li}
                                {/if} -->
                            </a>
                        {/if}
                    {/if}
                {/if}
            {/each}
        </div>
    {/each}
    {/if}
<!-- </nav> -->


<style>
</style>
