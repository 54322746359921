// console.log('main.ts');
import { get, readable, writable } from 'svelte/store';
import { cfg, client, page } from './stores';
import testing from './testing/testing_main.svelte';
// BEGIN: Aether core
import element_access_type from './mods/element_access_type.svelte';
import main_nav_menu_main from './menus/menu_main_nav.svelte';
import admin_main from './admin/0_admin_main.svelte';
// import log_client_viewing_li from './log_client_viewing/log_client_viewing.svelte';
// import person_li_manage from './person/person_li_manage.svelte';
// import person from './person/person.svelte';
import person_id_main from './person/0_person_id_main.svelte';
import person_id_quick from './person/0_person_id_quick.svelte';
import person_user_main from './person_user/0_person_user_main.svelte';
import site_main from './site/0_site_main.svelte';
// import user from './user/user.svelte';
// import user_create from './user/user_create.svelte';
import qr_scanning_main from './qr_scanner/0_qr_scanner_main.svelte';
// import qr_scanner from './qr_scanner/qr_scanner.svelte';
// BEGIN: Aether modules
// console.log(document.aether.cfg);
// console.log(document.aether.client);
// console.log(document.aether.page);
/* ### BEGIN ### Core Modules */
/* BEGIN: Core Module: Admin */
// Updated 2022-01-07
// In Use
if (document.querySelector('.svelte_target.admin_main')) {
    if (get(client).logged_in_check) {
        // Logged in.
        console.log(`Logged in.`);
        const admin_main_app = new admin_main({
            target: document.querySelector('.svelte_target.admin_main'),
            props: {}
        });
    }
    else {
        // Not logged in.
        console.log('Not logged in.');
    }
}
/* END: Core Module: Admin */
/* BEGIN: Core Module: Site */
// Updated 2022-07-13
// In Use
if (document.querySelector('.svelte_target.site_main')) {
    if (get(client).logged_in_check) {
        // Logged in.
        console.log(`Logged in.`);
        const site_main_app = new site_main({
            target: document.querySelector('.svelte_target.site_main'),
            props: {}
        });
    }
    else {
        // Not logged in.
        console.log('Not logged in.');
    }
}
/* END: Core Module: Site */
/* BEGIN: Core Module: Log Client Viewing */
// Updated 2021-??-??
// Commented out 2022-06-30
// if (document.getElementById('log_client_viewing_list_container')) {
//     const log_client_viewing_app = new log_client_viewing_li({
//         target: document.getElementById('log_client_viewing_list_container'),
//         props: {
//             // 'client': client,
//             // 'account_id': client['account_id'],
//             // 'client_person_id': client['person_id'],
//             // 'client_user_id': client['user_id'],
//         }
//     });
// }
/* END: Core Module: Log Client Viewing */
/* BEGIN: Core Module: Person Manage */
// Updated 2021-??-??
// Commented out 2022-06-30
// if (document.getElementById('person_li_manage_container')) {
//     const person_li_manage_app = new person_li_manage({
//         target: document.getElementById('person_li_manage_container'),
//         props: {
//             // 'client': client,
//             // 'account_id': client['account_id'],
//         }
//     });
// }
/* END: Core Module: Person Manage */
/* BEGIN: Core Module: Person */
// Updated 2021-??-??
// Commented out 2022-06-30
// if (document.getElementById('person_container')) {
//     const person_app = new person({
//         target: document.getElementById('person_container'),
//         props: {
//             // 'client': client,
//             // 'account_id': client['account_id'],
//             'person_id': person_id,
//         }
//     });
// }
// Updated 2022-11-30
if (document.querySelector('.svelte_target.person_id_main')) {
    const person_id_main_app = new person_id_main({
        target: document.querySelector('.svelte_target.person_id_main'),
        props: {}
    });
}
// Updated 2023-12-31
if (document.querySelector('.svelte_target.person_id_quick')) {
    const person_id_quick_app = new person_id_quick({
        target: document.querySelector('.svelte_target.person_id_quick'),
        props: {}
    });
}
/* END: Core Module: Person */
/* BEGIN: Core Module: User */
// Updated 2021-??-??
// Commented out 2022-06-30
// if (document.getElementById('user_container')) {
//     if (user_id) {
//         console.log(`Got a user_id: ${user_id}`);
//         const user_app = new user({
//             target: document.getElementById('user_container'),
//             props: {
//                 // 'client': client,
//                 // 'account_id': client['account_id'],
//                 'user_id': user_id,
//             }
//         });
//     } else {
//         console.log(`No user_id. Create user using person_id: ${person_id}`);
//         const user_create_app = new user_create({
//             target: document.getElementById('user_container'),
//             props: {
//                 // 'client': client,
//                 // 'account_id': client['account_id'],
//                 'administrator_check': administrator_check,
//             }
//         });
//     }
// }
/* END: Core Module: User */
/* BEGIN: Core Module: Person and User */
// Updated 2022-01-07
// In Use
if (document.querySelector('.svelte_target.person_user_main')) {
    let auth_key = null;
    let person_id = null;
    // let user_id = null;
    if (get(client).logged_in_check && get(page).page_for.person_id) {
        // Logged in. Given a Person ID in page_for to use that instead.
        console.log(`Logged in. Found page_for with Person ID: ${get(page).page_for.person_id}`);
        auth_key = get(page).page_for.auth_key;
        person_id = get(page).page_for.person_id;
    }
    else if (get(client).logged_in_check) {
        // Logged in. Use that Person ID.
        console.log(`Logged in. Found Client Person ID: ${document.aether.client.person_id} and Client User ID: ${document.aether.client.user_id}`);
        person_id = document.aether.client.person_id;
    }
    else {
        // Not logged in. Check if an auth_key and person_id is in the URL parameters. This is for a new person/user account that needs to be completed.
        console.log('Not logged in. Checking URL params for auth_key and person_id');
        const query_string = window.location.search;
        const url_params = new URLSearchParams(query_string);
        auth_key = url_params.get('auth_key');
        person_id = url_params.get('person_id');
    }
    const person_user_main_app = new person_user_main({
        target: document.querySelector('.svelte_target.person_user_main'),
        props: {
            'auth_key': auth_key,
            'person_id': person_id,
            // 'user_id': user_id,
        }
    });
}
/* END: Core Module: Person and User */
/* BEGIN: Core Module: QR Scanner Test */
// Updated 2022-02-11
// In Use Live and Demoing
if (document.querySelector('.svelte_target.qr_scanner_main')) {
    const qr_scanning_main_app = new qr_scanning_main({
        target: document.querySelector('.svelte_target.qr_scanner_main'),
        props: {}
    });
}
/* END: Core Module: QR Scanner Test */
/* ### END ### Core Modules */
/* ### BEGIN ### Other System Modules */
/* ### END ### Other System Modules */
/* BEGIN: Utilities */
// if (document.getElementById('target_qr_scanner_container')) {
//     const qr_scanner_app = new qr_scanner({
//         target: document.getElementById('target_qr_scanner_container'),
//         props: {
//         }
//     });
// }
/* END: Utilities */
/* BEGIN: Testing */
// Updated 2022-10-28
if (document.querySelector('.svelte_target.testing_ws_main')) {
    const testing_main_app = new testing({
        target: document.querySelector('.svelte_target.testing_ws_main'),
        props: {}
    });
}
// if (document.getElementById('System-Debug')) { // testing_svelte
//     const testing_component = new testing({
//         target: document.getElementById('System-Debug'),
//         props: {
//             'test_str': 'Scott',
//             'test_bool': true,
//             'test_obj': {testing: 'test test test'},
//         }
//     });
// }
/* END: Testing */
if (document.querySelector('.svelte_target.set_access_type')) {
    const element_access_type_app = new element_access_type({
        target: document.querySelector('.svelte_target.set_access_type'),
        props: {}
    });
}
if (document.querySelector('#Main-Nav-Menu.svelte_target.main_nav_menu')) {
    const main_nav_menu_app = new main_nav_menu_main({
        target: document.querySelector('#Main-Nav-Menu.svelte_target.main_nav_menu'),
        props: {}
    });
}
