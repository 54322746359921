// console.log('*** stores_user_api.js Stores ***');
import { get, readable, writable } from 'svelte/store';
import { account_id, cfg } from '../stores';
import { api } from 'aether_npm_lib';


/* BEGIN: Basic CRUD Object Related Functions */


// Updated 2022-01-11
export let create_user_obj = async function create_user_obj({person_id, params={}, data={}, return_obj=false, return_meta=false, log_lvl=0}) {
    console.log('*** stores_user_api.js: create_user_obj() ***');

    let endpoint = `/user`;
    if (return_obj) {
        params['return_obj'] = true;
    }
    data['person_id'] = person_id; // Required when creating a new user_obj
    let user_obj_post_promise = await api.post_object({api_cfg: get(cfg).api, endpoint: endpoint, params: params, data: data, return_obj: return_obj, return_meta: return_meta, log_lvl: log_lvl});
    // console.log(user_obj_post_promise);
    if (return_obj) {
        return user_obj_post_promise;
    } else {
        return user_obj_post_promise.user_id_random;
    }
}


// Updated 2021-12-31
export let update_user_obj = async function update_user_obj({user_id, person_id=null, params={}, data={}, return_obj=false, return_meta=false, log_lvl=0}) {
    console.log('*** stores_user_api.js: update_user_obj() ***');

    let endpoint = `/user/${user_id}`;
    if (return_obj) {
        params['return_obj'] = true;
    }
    if (person_id) {
        data['person_id'] = person_id;
    }
    let user_obj_patch_promise = await api.patch_object({api_cfg: get(cfg).api, endpoint: endpoint, params: params, data: data, return_obj: return_obj, return_meta: return_meta, log_lvl: log_lvl});
    // console.log(user_obj_patch_promise);
    if (return_obj) {
        return user_obj_patch_promise;
    } else {
        return user_obj_patch_promise.user_id_random;
    }
}


// Updated 2021-12-31
export let load_user_obj = async function load_user_obj({user_id, params={}, return_meta=false, log_lvl=0}) {
    console.log('*** stores_user_api.js: load_user_obj() ***');

    const endpoint = `/user/${user_id}`;
    // params['param_name'] = null;
    let user_obj_get_promise = await api.get_object({api_cfg: get(cfg).api, endpoint: endpoint, params: params, return_meta: return_meta, log_lvl: log_lvl});
    // console.log(user_obj_get_promise);
    return user_obj_get_promise;
}


// Updated 2021-12-31
export let delete_user_obj = async function delete_user_obj({user_id, params={}, return_meta=false, log_lvl=0}) {
    console.log('*** stores_user_api.js: delete_user_obj() ***');

    const endpoint = `/user/${user_id}`;
    // params['param_name'] = null;
    let user_obj_delete_promise = await api.delete_object({api_cfg: get(cfg).api, endpoint: endpoint, params: params, return_meta: return_meta, log_lvl: log_lvl});
    // console.log(user_obj_delete_promise);
    return user_obj_delete_promise;
}


/* END: Basic CRUD Object Related Functions */


/* BEGIN: More Specific Object Related Functions */


// Updated 2021-12-31
export let email_user_obj_auth_key_url = async function email_user_obj_auth_key_url({user_id, root_url, params={}, return_meta=false, log_lvl=0}) {
    console.log('*** stores_user_api.js: email_user_obj_auth_key_url() ***');

    const endpoint = `/user/${user_id}/email_auth_key_url`;
    params['root_url'] = root_url;
    let user_obj_get_promise = await api.get_object({api_cfg: get(cfg).api, endpoint: endpoint, params: params, return_meta: return_meta, log_lvl: log_lvl});
    // console.log(user_obj_get_promise);
    return user_obj_get_promise;
}


// Updated 2022-01-07
export let get_user_obj_auth_key = async function get_user_obj_auth_key({user_id, params={}, return_obj=false, return_meta=false, log_lvl=0}) {
    console.log('*** stores_user_api.js: get_user_obj_auth_key() ***');

    const endpoint = `/user/${user_id}/new_auth_key`;
    if (return_obj) {
        params['return_obj'] = true;
    }
    let user_obj_get_promise = await api.get_object({api_cfg: get(cfg).api, endpoint: endpoint, params: params, return_meta: return_meta, log_lvl: log_lvl});
    // console.log(user_obj_get_promise);
    if (return_obj) {
        return user_obj_get_promise;
    } else {
        return user_obj_get_promise.auth_key;
    }
}


// Updated 2021-12-31
export let lookup_user_obj_email = async function lookup_user_obj_email({account_id, params={}, return_meta=false, log_lvl=0}) {
    console.log('*** stores_user_api.js: lookup_user_obj_email() ***');

    const endpoint = `/user/lookup_email`;
    // params['param_name'] = null;
    // params['enabled'] = 'all';
    let user_obj_li_get_promise = await api.get_object({api_cfg: get(cfg).api, endpoint: endpoint, params: params, return_meta: return_meta, log_lvl: log_lvl});
    // console.log(user_obj_get_promise);
    return user_obj_li_get_promise;
}


/* END: More Specific Object Related Functions */