// console.log('*** stores_person_api.js Stores ***');
import { get, readable, writable } from 'svelte/store';
import { account_id, cfg } from '../stores';
import { api } from 'aether_npm_lib';


/* BEGIN: Basic CRUD Object Related Functions */


// Updated 2022-11-30
export let create_person_obj = async function create_person_obj({account_id, params={}, data={}, return_obj=false, return_meta=false, log_lvl=0}) {
    console.log('*** stores_person_api.js: create_person_obj() ***');

    let endpoint = `/person`;
    if (return_obj) {
        params['return_obj'] = true;
        params['inc_address'] = true;
        params['inc_contact'] = true;
        params['inc_user'] = true;
    }
    data['account_id_random'] = account_id; // Required when creating a new person_obj
    let person_obj_post_promise = await api.post_object({api_cfg: get(cfg).api, endpoint: endpoint, params: params, data: data, return_obj: return_obj, return_meta: return_meta, log_lvl: log_lvl});
    // console.log(person_obj_post_promise);
    if (return_obj) {
        return person_obj_post_promise;
    } else {
        return person_obj_post_promise.person_id_random;
    }
}


// Updated 2021-12-31
export let update_person_obj = async function update_person_obj({person_id, params={}, data={}, return_obj=false, return_meta=false, log_lvl=0}) {
    console.log('*** stores_person_api.js: update_person_obj() ***');

    // let endpoint = `/v3/person/${person_id}/exist`;
    let endpoint = `/person/${person_id}`;
    if (return_obj) {
        params['return_obj'] = true;
        params['inc_address'] = true;
        params['inc_contact'] = true;
        params['inc_user'] = true;
    }
    let person_obj_patch_promise = await api.patch_object({api_cfg: get(cfg).api, endpoint: endpoint, params: params, data: data, return_obj: return_obj, return_meta: return_meta, log_lvl: log_lvl});
    // console.log(person_obj_patch_promise);
    if (return_obj) {
        return person_obj_patch_promise;
    } else {
        return person_obj_patch_promise.person_id_random;
    }
}


// Updated 2021-12-31
export let load_person_obj = async function load_person_obj({person_id, auth_key=null, params={}, return_meta=false, log_lvl=0}) {
    console.log('*** stores_person_api.js: load_person_obj() ***');

    const endpoint = `/person/${person_id}`;
    if (auth_key) { // Not required, but if passed then it must match with the person_id
       params['auth_key'] = auth_key;
    }
    let person_obj_get_promise = await api.get_object({api_cfg: get(cfg).api, endpoint: endpoint, params: params, return_meta: return_meta, log_lvl: log_lvl});
    // console.log(person_obj_get_promise);
    return person_obj_get_promise;
}


// Updated 2022-11-30
// This is new and should replace the function above (load_person_obj)
export let get_person_obj = async function get_person_obj({person_id, inc_address=false, inc_contact=false, inc_organization=false, auth_key=null, params={}, log_lvl=0}) {
    console.log('*** stores_person_api.js: get_person_obj() ***');

    const endpoint = `/person/${person_id}`;
    if (inc_address) {
        params['inc_address'] = true;
    }
    if (inc_contact) {
        params['inc_contact'] = true;
     }
    if (inc_organization) {
       params['inc_organization'] = true;
    }
    // if (inc_person_something_list) {
    //     params['inc_person_something_list'] = true;
    // }
    if (auth_key) { // Not required, but if passed then it must match with the person_id
        params['auth_key'] = auth_key;
     }
    let person_obj_get_promise = await api.get_object({api_cfg: get(cfg).api, endpoint: endpoint, params: params, timeout: 50000, log_lvl: log_lvl});

    let person_obj = person_obj_get_promise;
    return person_obj;
}


// Updated 2021-12-31
export let delete_person_obj = async function delete_person_obj({person_id, params={}, return_meta=false, log_lvl=0}) {
    console.log('*** stores_person_api.js: delete_person_obj() ***');

    const endpoint = `/person/${person_id}`;
    // params['param_name'] = null;
    let person_obj_delete_promise = await api.delete_object({api_cfg: get(cfg).api, endpoint: endpoint, params: params, return_meta: return_meta, log_lvl: log_lvl});
    // console.log(person_obj_delete_promise);
    return person_obj_delete_promise;
}


/* END: Basic CRUD Object Related Functions */


/* BEGIN: More Specific Object Related Functions */


// Updated 2021-12-31
export let create_person_obj_new = async function create_person_obj_new({params={}, data={}, return_obj=false, return_meta=false, log_lvl=0}) {
    console.log('*** stores_person_api.js: create_person_obj_new() ***');

    let endpoint = `/v3/person/new`;
    let person_obj_post_promise = await api.post_object({api_cfg: get(cfg).api, endpoint: endpoint, params: params, data: data, return_obj:false, return_meta: return_meta, log_lvl: log_lvl});
    console.log(person_obj_post_promise);
    if (person_obj_post_promise) {
        if (return_obj) {
            console.log('Returning object')
            return person_obj_post_promise;
        } else {
            console.log(`Returning ID: ${person_obj_post_promise.person_id_random}`);
            return person_obj_post_promise.person_id_random;
        }
    } else {
        console.log('Returning false')
        return false;
    }
}


// Updated 2021-12-31
export let email_person_obj_auth_key_url = async function email_person_obj_auth_key_url({person_id, root_url, params={}, return_meta=false, log_lvl=0}) {
    console.log('*** stores_person_api.js: email_person_obj_auth_key_url() ***');

    const endpoint = `/person/${person_id}/email_auth_key_url`;
    // params['enabled'] = 'all';
    params['root_url'] = root_url;
    let person_obj_get_promise = await api.get_object({api_cfg: get(cfg).api, endpoint: endpoint, params: params, return_meta: return_meta, log_lvl: log_lvl});
    // console.log(person_obj_get_promise);
    return person_obj_get_promise;
}


// Updated 2021-12-31
export let lookup_person_obj_email = async function lookup_person_obj_email({params={}, return_meta=false, log_lvl=0}) {
    console.log('*** stores_person_api.js: lookup_person_obj_email() ***');

    const endpoint = `/person/lookup_email`;
    // params['enabled'] = 'all';
    let person_obj_li_get_promise = await api.get_object({api_cfg: get(cfg).api, endpoint: endpoint, params: params, return_meta: return_meta, log_lvl: log_lvl});
    // console.log(person_obj_get_promise);
    return person_obj_li_get_promise;
}


/* END: More Specific Object Related Functions */



/* BEGIN: Lookup Related */
export let get_lu_country_subdivision_li = async function get_lu_country_subdivision_li({test=null, params={}, log_lvl=0}) {
    console.log('*** stores_person_api.js: get_lu_country_subdivision_li() ***');

    const endpoint = `/lu/country_subdivision/list`;
    let lu_country_subdivision_li_get_promise = await api.get_object({api_cfg: get(cfg).api, endpoint: endpoint, params: params, log_lvl: log_lvl});

    let lu_country_subdivision_li = lu_country_subdivision_li_get_promise;
    console.log(lu_country_subdivision_li);
    return lu_country_subdivision_li;
}

export let get_lu_country_li = async function get_lu_country_li({test=null, params={}, log_lvl=0}) {
    console.log('*** stores_person_api.js: get_lu_country_li() ***');

    const endpoint = `/lu/country/list`;
    let lu_country_li_get_promise = await api.get_object({api_cfg: get(cfg).api, endpoint: endpoint, params: params, log_lvl: log_lvl});

    let lu_country_li = lu_country_li_get_promise;
    console.log(lu_country_li);
    return lu_country_li;
}
/* END: Lookup Related */