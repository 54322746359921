<script lang="ts">
import { onMount } from 'svelte';

import { ae, api, Element_qr_scanner } from 'aether_npm_lib';

import { account_id, cfg, client, page, ae_com } from '../stores';

type key_val = {
	[key: string]: any; // variable key
	// name: string;
};

if (!$ae_com.app) {
    $ae_com.app = {};
}
if (!$ae_com.app.qr) {
    $ae_com.app.qr = {start_scanner: true};
    $ae_com.app.qr = {camera_status: 'unknown'};
}

let show: string = 'qr_scanner' // 'qr_codes', 'qr_scanner'
let show_requirements: boolean = false;
let auto_view: boolean = true; // auto view the scan results
let show_raw_result: boolean = false; // show the raw string results
let show_processed_result: boolean = true; // show the processed scan results
let qr_scan_result: string = '';
let qr_scan_obj: Object = {};
let qr_scan_entry_method: string = null;
let hide_qr_front: boolean = null;
let hide_qr_back: boolean = null;

let me_card_static_id = 's7HKeSn2wW0';
// let me_card_random_id = '';
let person_id = '3H6eIuT52DU'; // example does not exist
let event_badge_id_1 = 'JPUG-87-80-88'; // Scott Idem
let event_badge_id_2 = 'GLMB-80-63-55'; // Jane Doe
let event_badge_id_3 = 'CYJB-20-35-84'; // John Doe
let event_badge_id_4 = 'TVWW-84-80-41'; // Albert Einstein
let event_badge_id_5 = 'XHTX-23-20-42'; // Marie Curie
let event_exhibit_id = '_NLWkGdrRI8';
let event_person_id = '9L_1-_TlW0M';
let event_presenter_id = 'QLz6fsqs5P4'; // example does not exist
let event_session_id = '8YeXDo_OuHk'; // example does not exist

let full_name = 'Example Name';
let email = 'example.name@example.com';
let full_address = '12345 Fake Street, Springfield XX 12345 United States';

let example_string = 'Enter text here to create a new QR code with this text.';

// let generate_qr_id_get_promise;
let example_event_badge_1_qr_img_src_promise;
let example_event_badge_1_qr_img_src = null;
let example_event_badge_2_qr_img_src_promise;
let example_event_badge_2_qr_img_src = null;
let example_event_badge_3_qr_img_src_promise;
let example_event_badge_3_qr_img_src = null;
let example_event_badge_4_qr_img_src_promise;
let example_event_badge_4_qr_img_src = null;
let example_event_badge_5_qr_img_src_promise;
let example_event_badge_5_qr_img_src = null;

// var successCallback = function(error) {
//   // user allowed access to camera
//   console.log('Camera access allowed!');
// };
// var errorCallback = function(error) {
//   if (error.name == 'NotAllowedError') {
//     // user denied access to camera
//     console.log('Camera access not allowed!?');
//   }
// };
// navigator.mediaDevices.getUserMedia({video: true})
//   .then(successCallback, errorCallback);

$: handle_example_string(example_string)

/* BEGIN: Lifecycle functions (onMount, beforeUpdate, afterUpdate,
onDestroy, tick) */
onMount(() => {
    console.log('** Component Mounted: ** Core QR Scanner Main');

    if(window.location.hash) {
        var hash = window.location.hash;

        if (hash == '#qr_scanner') {
            show = 'qr_scanner';
        } else if (hash == '#qr_codes') {
            show = 'qr_codes';
        }
    }

    create_example_qr_codes();
});


function handle_qr_scan_result(event) {
    console.log('*** handle_qr_scan_result() ***');

    qr_scan_obj = null;

    qr_scan_result = event.detail.result; // Currently this contains a raw string of the QR code data. The value for "event.detail.text" is the same as "event.detail.result" for now.
    console.log(qr_scan_result);
    qr_scan_obj = ae.util.process_data_string(qr_scan_result);

    qr_scan_entry_method = event.detail.entry_method;
    console.log(qr_scan_entry_method);

    if (qr_scan_obj.type && qr_scan_obj.id && qr_scan_obj.type == 'event_badge') {
        console.log(`Found event_badge ID: ${qr_scan_obj.id}`);
    } else if (qr_scan_obj.type && qr_scan_obj.id && qr_scan_obj.type == 'event_exhibit') {
        console.log(`Found event_exhibit ID: ${qr_scan_obj.id}`);
    } else if (qr_scan_obj.type && qr_scan_obj.id && qr_scan_obj.type == 'event_person') {
        console.log(`Found event_person ID: ${qr_scan_obj.id}`);
    } else if (qr_scan_obj.type && qr_scan_obj.id && qr_scan_obj.type == 'event_session') {
        console.log(`Found event_session ID: ${qr_scan_obj.id}`);
    } else if (qr_scan_obj.type && qr_scan_obj.id && qr_scan_obj.type == 'http') {
        console.log(`URL: ${qr_scan_obj.id}`);
    } else {
        console.log('The object returned was unexpected or not valid');
        console.log(qr_scan_obj);
    }
}


function handle_qr_camera(event) {
    console.log('*** handle_qr_camera() ***');

    // console.log(event.detail);

    if (event.detail.status == 'allowed') {
        // console.log('Camera access allowed');
        $ae_com.app.qr.camera_status = 'allowed';
    } else if (event.detail.status == 'denied') {
        console.log('Camera access denied!?');
        $ae_com.app.qr.camera_status = 'denied';
    }
}


function create_example_qr_codes() {
    console.log('*** create_example_qr_codes() ***');

    let qr_get_promise = null;
    let endpoint = null;
    let params = {};

    // Generate the MeCard QR code
    // endpoint = `/event/qr_image/test_mecard_${me_card_static_id}/gen_send`;
    // console.log(endpoint)
    // params = {};
    // params['qr_type'] = 'mecard';
    // params['qr_send'] = false;
    // params['n'] = full_name;
    // params['email'] = email;
    // params['adr'] = full_address;
    // console.log(params);
    // qr_get_promise = await api.get_object({api_cfg: $cfg.app, endpoint: endpoint, params: params, log_lvl: 2});

     // Generate string QR code
    // endpoint = `/event/qr_image/test_string/gen_send`;
    // console.log(endpoint)
    // params = {};
    // params['qr_type'] = 'str';
    // params['qr_send'] = false;
    // params['str'] = example_string;
    // console.log(params);
    // qr_get_promise = await api.get_object({api_cfg: $cfg.app, endpoint: endpoint, params: params, log_lvl: 2});

    // Generate the Aether object QR codes
    // Example Person ID
    // endpoint = `/event/qr_image/person_obj_${person_id}/gen_send`
    // params = {};
    // params['qr_type'] = 'obj';
    // params['qr_send'] = false;
    // params['obj_type'] = 'person';
    // params['obj_id'] = person_id;
    // console.log(params);
    // qr_get_promise = await api.get_object({api_cfg: $cfg.app, endpoint: endpoint, params: params, log_lvl: 2});

    example_event_badge_1_qr_img_src_promise = generate_qr_code({ qr_type: 'obj', qr_id: event_badge_id_1, obj_type: 'event_badge', obj_id: event_badge_id_1 }).then(function (result) {
        example_event_badge_1_qr_img_src = result;
        return result;
    })
    .catch(function (error) {
        console.log('Error:', error);
        return false;
    });

    example_event_badge_2_qr_img_src_promise = generate_qr_code({ qr_type: 'obj', qr_id: event_badge_id_2, obj_type: 'event_badge', obj_id: event_badge_id_2 }).then(function (result) {
        example_event_badge_2_qr_img_src = result;
        return result;
    })
    .catch(function (error) {
        console.log('Error:', error);
        return false;
    });

    example_event_badge_3_qr_img_src_promise = generate_qr_code({ qr_type: 'obj', qr_id: event_badge_id_3, obj_type: 'event_badge', obj_id: event_badge_id_3 }).then(function (result) {
        example_event_badge_3_qr_img_src = result;
        return result;
    })
    .catch(function (error) {
        console.log('Error:', error);
        return false;
    });

    example_event_badge_4_qr_img_src_promise = generate_qr_code({ qr_type: 'obj', qr_id: event_badge_id_4, obj_type: 'event_badge', obj_id: event_badge_id_4 }).then(function (result) {
        example_event_badge_4_qr_img_src = result;
        return result;
    })
    .catch(function (error) {
        console.log('Error:', error);
        return false;
    });

    example_event_badge_5_qr_img_src_promise = generate_qr_code({ qr_type: 'obj', qr_id: event_badge_id_5, obj_type: 'event_badge', obj_id: event_badge_id_5 }).then(function (result) {
        example_event_badge_5_qr_img_src = result;
        return result;
    })
    .catch(function (error) {
        console.log('Error:', error);
        return false;
    });
}

async function generate_qr_code({qr_type, qr_id, obj_type=null, obj_id=null}) {
    console.log('*** generate_qr_code() ***');

    let filename = `qr_${$client.account_id}_${qr_id}.png`;

    let return_blob = true;

    let endpoint = `/qr/${$account_id}/${qr_id}`;
    // console.log('Endpoint', endpoint);
    let params = {};
    params['regen'] = true; // Regenerate the file even if nothing has changed.
    params['return_file'] = return_blob;

    if (qr_type == 'obj') {
        params['qr_type'] = 'obj'; // mecard, obj, vcard
        params['qr_send'] = return_blob;
        params['obj_type'] = obj_type;
        params['obj_id'] = obj_id;
        console.log('Params', params);

        filename = `qr_${$client.account_id}_${qr_id}_obj.png`;
    }

    let generate_qr_id_get_promise;
    generate_qr_id_get_promise = await api.get_object({ api_cfg: $cfg.api, endpoint: endpoint, params: params, return_blob: return_blob, filename: filename, auto_download: false, log_lvl: 0 });

    let file_blob = new Blob([generate_qr_id_get_promise.data]);
    // console.log(file_blob);
    let file_obj_url = URL.createObjectURL(file_blob); // The img src
    console.log(`URL for img src: ${file_obj_url}`);

    return file_obj_url;
}

async function handle_example_string() {
    console.log('*** handle_example_string() ***');

    let qr_get_promise = null;
    let endpoint = null;
    let params = {};

    // Generate string QR code
    endpoint = `/event/qr_image/test_string/gen_send`;
    console.log(endpoint)
    params = {};
    params['qr_type'] = 'str';
    params['qr_send'] = false;
    params['str'] = example_string;
    console.log(params);
    qr_get_promise = await api.get_object({api_cfg: $cfg.app, endpoint: endpoint, params: params, log_lvl: 2});
}
</script>


<section class="ae_main qr_scanner_main">
    <header>
        <h1>QR Scanner Testing Page</h1>
    </header>

    <p>
        This page is used to test QR scanning with your device.
        {#if $ae_com.app.qr.camera_status == 'unknown'}
            <strong>You will need to allow access to your device's camera when asked.</strong>
        {:else if $ae_com.app.qr.camera_status == 'denied'}
            <strong>You need to allow access to your device's camera. Currently this seems to be blocked or denied for this site.</strong>
            Please check your browser's permissions.
        {/if}
    </p>

    <!-- {$ae_com.app.qr.camera_status} -->

    <div class="ae_options">
        {#if show == 'qr_scanner'}
            <button class="ae_btn btn_lg btn_primary margin_md" on:click={() => show='qr_codes'}><span class="fas fa-qrcode"></span> Show Example QR Codes</button>
        {:else if show == 'qr_codes'}
            <button class="ae_btn btn_lg btn_primary margin_md" on:click={() => show='qr_scanner'}><span class="fas fa-search"></span> Show QR Scanner</button>
        {/if}

        {#if (show == 'qr_scanner' && show_requirements)}
            <button class="ae_btn btn_lg btn_info margin_md" on:click={() => show_requirements=false}><span class="fas fa-info"></span> Hide Requirements</button>
        {:else if (show == 'qr_scanner' && !show_requirements)}
            <button class="ae_btn btn_lg btn_info margin_md" on:click={() => show_requirements=true}><span class="fas fa-info"></span> Requirements</button>
        {/if}
    </div>

    {#if show == 'qr_scanner'}
        <section class="test_qr_scanner">

            <div>
                {#if show_requirements}
                    <p>You will need a device with a camera to scan the QR codes. You will also of course need one or more valid QR codes to scan. <button class="ae_btn btn_sm" on:click={() => show='qr_codes'}><span class="fas fa-qrcode"></span> Example QR Codes</button></p>
                    <ul>
                        <li>Most laptops, workstations, Android phones/tablets, iPhones, and iPads are compatible</li>
                        <li>The device must have a current (within the last 4 years) web browser such as Google Chrome, Mozilla Firefox, Apple's Safari, or Microsoft Edge. Opera is not yet fully supported.</li>
                        <li>There is no Android or Apple app that needs to be installed!</li>
                        <li>The device must have a camera for scanning or you will need to manually enter attendee badge IDs.</li>
                        <li>The only permission you need to allow is access to your device's camera when asked.</li>
                    </ul>
                {:else}
                    <!-- <button class="ae_btn btn_sm btn_info" on:click={() => show_requirements=true}><span class="fas fa-eye"></span> Requirements</button> -->
                {/if}


            </div>

            <div class="qr_scanner_form">
                <!-- <label>Show QR Data on Scan? <input type="checkbox" bind:checked={auto_view} value="true"></label> -->

                <Element_qr_scanner start_qr_scanner={true} show_pause_btn={false} show_qr_manual_text_entry_option={false} show_qr_manual_badge_id_entry_option={true} show_qr_scan_result={false} on:qr_scan_result={handle_qr_scan_result} on:qr_camera={handle_qr_camera} />

                <!-- <Element_qr_scanner show_qr_scan_result={false} show_qr_manual_badge_id_entry_option={true} on:qr_scan_result={handle_qr_scan_result} /> -->

                <div class="qr_scan_result">
                {#if auto_view && qr_scan_result}
                    <div class="qr_scan_entry_method">
                        {#if qr_scan_entry_method == 'QR'}
                            Successful scan of QR code
                        {:else if qr_scan_entry_method == 'manual'}
                            Successful manual entry
                        {/if}
                    </div>

                    <div class="qr_scan_raw_result">
                        <span class="label">Raw Result:</span>
                        <span id="qr_scan_result_value" class="value">{qr_scan_result}</span>
                    </div>

                    <div class="qr_scan_processed_result">
                        <div>
                            <span class="label">QR Type:</span>
                            <span class="value">{qr_scan_obj.qr_type}</span>
                        </div>

                        <div>
                        {#if qr_scan_obj.qr_type == 'OBJ'}
                            <span class="label">Object type and ID:</span>
                                <span class="value">{qr_scan_obj.type}</span>: <span class="value">{qr_scan_obj.id}</span>
                        {:else if qr_scan_obj.qr_type == 'MECARD' || qr_scan_obj.qr_type == 'STR' || qr_scan_obj.qr_type == 'UNKNOWN'}
                            <span class="label">String value:</span>
                            <span class="value">{qr_scan_obj.str}</span>
                        {:else if qr_scan_obj.qr_type == 'JSON'}
                            <span class="label">JSON value:</span>
                            <span id="qr_scan_result_value" class="value">{qr_scan_obj.json}</span>
                        {/if}
                        </div>
                    </div>
                {:else if qr_scan_result}
                    <div class="qr_scanned">QR code was scanned</div>
                {:else}
                    Waiting for QR code scan...
                {/if}
                </div>
            </div>
        </section>
    {:else if show == 'qr_codes'}
        <section class="example_qr_codes">
            <h2>Example QR Codes Are Below</h2>

            <!-- <div class="container any_string">
                <h3>Any Text:</h3>
                <textarea bind:value={example_string}></textarea><br>
                <img class="qr_code" style="" src="/event/qr_image/test_string?qr_filename=test_string_qr.png" alt="missing QR code">
            </div> -->

            <!-- <img class="qr_code" style="" src="/event/qr_image/test_mecard_{me_card_random_id}?qr_filename=random_mecard_qr.png" alt="missing QR code"> -->

            <!-- <div class="container person">
                <h3>Person ID: {person_id}:</h3>
                <img class="qr_code" style="" src="/event/qr_image/person_obj_{person_id}?qr_filename=person_qr.png" alt="missing QR code">
                <p>This is an example of a QR code for a specific person in this system. This can be used to authenticate a person with their unique ID encoded in the QR code image.</p>
            </div> -->

            <div class="container event_badge">
                <h3>Event Badge ID 1: Scott Idem ({event_badge_id_1}):</h3>
                <!-- <img class="qr_code" style="" src="/event/qr_image/event_badge_obj_{event_badge_id_1}?qr_filename=event_badge_qr.png" alt="missing QR code"> -->

                {#await example_event_badge_1_qr_img_src_promise}
                    Generating...
                {:then result}
                    {#if example_event_badge_1_qr_img_src}
                        <img
                            class="qr_code obj_qr"
                            style=""
                            class:v_hide_print={hide_qr_front}


                            src={example_event_badge_1_qr_img_src}
                            alt="missing QR code"

                            on:dblclick={() => {
                                // (hide_qr_front) ? hide_qr_front = !hide_qr_front : hide_qr_front;
                                (hide_qr_front) ? hide_qr_front = false : hide_qr_front = true;
                            }}
                            />
                    {/if}
                {/await}

                <h3>Event Badge ID 2: Jane Doe ({event_badge_id_2}):</h3>
                <!-- <img class="qr_code" style="" src="/event/qr_image/event_badge_obj_{event_badge_id_2}?qr_filename=event_badge_qr.png" alt="missing QR code"> -->
                {#await example_event_badge_2_qr_img_src_promise}
                    Generating...
                {:then result}
                    {#if example_event_badge_2_qr_img_src}
                        <img
                            class="qr_code obj_qr"
                            style=""
                            class:v_hide_print={hide_qr_front}


                            src={example_event_badge_2_qr_img_src}
                            alt="missing QR code"

                            on:dblclick={() => {
                                // (hide_qr_front) ? hide_qr_front = !hide_qr_front : hide_qr_front;
                                (hide_qr_front) ? hide_qr_front = false : hide_qr_front = true;
                            }}
                            />
                    {/if}
                {/await}

                <h3>Event Badge ID 3: John Doe ({event_badge_id_3}):</h3>
                <!-- <img class="qr_code" style="" src="/event/qr_image/event_badge_obj_{event_badge_id_3}?qr_filename=event_badge_qr.png" alt="missing QR code"> -->
                {#await example_event_badge_3_qr_img_src_promise}
                    Generating...
                {:then result}
                    {#if example_event_badge_3_qr_img_src}
                        <img
                            class="qr_code obj_qr"
                            style=""
                            class:v_hide_print={hide_qr_front}


                            src={example_event_badge_3_qr_img_src}
                            alt="missing QR code"

                            on:dblclick={() => {
                                // (hide_qr_front) ? hide_qr_front = !hide_qr_front : hide_qr_front;
                                (hide_qr_front) ? hide_qr_front = false : hide_qr_front = true;
                            }}
                            />
                    {/if}
                {/await}

                <h3>Event Badge ID 4: Albert Einstein ({event_badge_id_4}):</h3>
                <!-- <img class="qr_code" style="" src="/event/qr_image/event_badge_obj_{event_badge_id_4}?qr_filename=event_badge_qr.png" alt="missing QR code"> -->
                {#await example_event_badge_4_qr_img_src_promise}
                    Generating...
                {:then result}
                    {#if example_event_badge_4_qr_img_src}
                        <img
                            class="qr_code obj_qr"
                            style=""
                            class:v_hide_print={hide_qr_front}


                            src={example_event_badge_4_qr_img_src}
                            alt="missing QR code"

                            on:dblclick={() => {
                                // (hide_qr_front) ? hide_qr_front = !hide_qr_front : hide_qr_front;
                                (hide_qr_front) ? hide_qr_front = false : hide_qr_front = true;
                            }}
                            />
                    {/if}
                {/await}

                <h3>Event Badge ID 5: Marie Curie ({event_badge_id_5}):</h3>
                <!-- <img class="qr_code" style="" src="/event/qr_image/event_badge_obj_{event_badge_id_5}?qr_filename=event_badge_qr.png" alt="missing QR code"> -->
                {#await example_event_badge_5_qr_img_src_promise}
                    Generating...
                {:then result}
                    {#if example_event_badge_5_qr_img_src}
                        <img
                            class="qr_code obj_qr"
                            style=""
                            class:v_hide_print={hide_qr_front}


                            src={example_event_badge_5_qr_img_src}
                            alt="missing QR code"

                            on:dblclick={() => {
                                // (hide_qr_front) ? hide_qr_front = !hide_qr_front : hide_qr_front;
                                (hide_qr_front) ? hide_qr_front = false : hide_qr_front = true;
                            }}
                            />
                    {/if}
                {/await}

                <p>These are examples of QR code for the attendee badges in this system. This can be used to look up their badge and registration information with the unique ID encoded in the QR code image. This can be used for exhibitor lead retrieval or to check someone into the conference, a session, a workshop, or some other function that requires special access.</p>
            </div>

            <!-- <hr /> -->

            <!-- <div class="container event_exhibit">
                <h3>Event Exhibit ID: {event_exhibit_id}:</h3>
                <img class="qr_code" style="" src="/event/qr_image/event_exhibit_obj_{event_exhibit_id}?qr_filename=event_exhibit_qr.png" alt="missing QR code">
                <p>This is an example of a QR code for an exhibitor in this system. This can be used in combination with an attendee's badge QR code to link the attendee to the exhibitor for lead retrieval.</p>
            </div> -->

            <!-- <hr /> -->

            <!-- <div class="container event_person">
                <h3>Event Person ID: {event_person_id}:</h3>
                <img class="qr_code" style="" src="/event/qr_image/event_person_obj_{event_person_id}?qr_filename=event_person_qr.png" alt="missing QR code">
                <p>This is an example of a QR code for a person registered for an event in this system. This can be used to look up their attendee details with the unique ID encoded in the QR code image.</p>
            </div> -->

            <!-- <hr /> -->

            <!-- <div class="container event_presenter">
                <h3>Event Presenter ID: {event_presenter_id}:</h3>
                <img class="qr_code" style="" src="/event/qr_image/event_presenter_obj_{event_presenter_id}?qr_filename=event_presenter_qr.png" alt="missing QR code">
                <p>This is an example of a QR code for a presenter in this system. This can be used to look up their presentation with the unique ID encoded in the QR code image.</p>
            </div> -->

            <!-- <hr /> -->

            <!-- <div class="container event_session">
                <h3>Event Session ID: {event_session_id}:</h3>
                <img class="qr_code" style="" src="/event/qr_image/event_session_obj_{event_session_id}?qr_filename=event_session_qr.png" alt="missing QR code">
                <p>This is an example of a QR code for an event session in this system. This can be used in combination with an attendee's badge QR code to link the attendee to the session for attendee tracking.</p>
            </div> -->

            <!-- <hr /> -->

            <!-- <div class="container static_mecard">
                <div>
                    <h3>Example MeCard 1 <span class="me_card_static_id">({me_card_static_id})</span></h3>
                    Full Name: {full_name}<br />
                    Email: {email}<br />
                    Full Address: {full_address}
                </div>
                <img class="qr_code" style="" src="/event/qr_image/test_mecard_{me_card_static_id}?qr_filename=static_mecard_qr.png" alt="missing QR code">
                <p>This is an example of a QR code in the <a href="https://en.wikipedia.org/wiki/MeCard_(QR_code)">MeCard format</a>. This is commonly used like a business card or contact in an address book. It can store a person's name, email address, phone number, URL, notes, and other personal information.</p>
            </div> -->

        </section>
    {/if}
</section>


<style>
/* :global(.qr_scanner) {
    outline: dashed medium pink;
} */

/* .me_card_static_id {
    font-size: smaller;
    color: gray;
} */

/* .qr_scan_raw_result {
    background-color: lightpink;
} */

/* .qr_scan_processed_result, .qr_scanned {
    background-color: lightyellow;
} */
</style>
