<script lang="ts">
import { createEventDispatcher, onMount } from 'svelte';
import { fade } from 'svelte/transition';

import { ae, Element_input, Element_input_v2 } from 'aether_npm_lib';

import { account_id, cfg, client, page, slct, slct_trigger, time, ae_com } from '../stores';

import { get_lu_country_li, get_lu_country_subdivision_li, get_person_obj, create_person_obj, delete_person_obj, update_person_obj } from './stores_person_api.js';

export let container_class_li = [];

const dispatch = createEventDispatcher();

let create_person_obj_promise;
let person_obj_delete_promise;
let update_person_obj_promise;
let lu_country_li_get_promise;
let lu_country_subdivision_li_get_promise;

let disable_submit_btn = true;

let lu_country_subdivision_li = null;
let lu_country_li = null;

onMount(() => {
    console.log('** Component Mounted: ** AE Person: Edit (Create) Quick');

    document.getElementById('person__given_name--').focus();

    if ($slct.person_id) {
        console.log(`Person ID selected: ${$slct.person_id}`);
    } else {
        // $slct.person_id = null;
    }

    if ($slct.person_obj) {
        console.log('Person Object selected:', $slct.person_obj);
    } else {
        // $slct.person_obj = {informal_name: 'null', title_names: 'null'};
        console.log('Person Object started:', $slct.person_obj);
    }
});

// $: if ($slct.person_obj) {
//     console.log('Selected person presenter object changed?', $slct.person_obj);
//     console.log($slct.person_obj);

//     if ($slct.person_obj == null) {
//         console.log('Selected presenter is null');
//         $slct.person_obj = { informal_name: null, title_names: null, given_name: null, middle_name: null, family_name: null, designations: null, professional_title: null, display_name: null, full_name: null, affiliations: null, email: null, biography: null, notes: null };
//         console.log($slct.person_obj);
//     } else {
//         disable_submit_btn = false;
//         console.log($slct.person_obj);
//     }
// }


// async function handle_get_lu_country_subdivision_li() {
//     lu_country_subdivision_li_get_promise = await get_lu_country_subdivision_li({log_lvl:1});
//     lu_country_subdivision_li = lu_country_subdivision_li_get_promise;
//     console.log(lu_country_subdivision_li);
// }
// handle_get_lu_country_subdivision_li();


// async function handle_get_lu_country_li() {
//     lu_country_li_get_promise = await get_lu_country_li({log_lvl:0});
//     lu_country_li = lu_country_li_get_promise;
//     console.log(lu_country_li);
// }
// handle_get_lu_country_li();


async function handle_submit_form(person){
    console.log('*** handle_submit_form() ***');

    disable_submit_btn = true;

    let form_data = new FormData(person.target);
    console.log(form_data);

    let find_obj_type = '';

    find_obj_type = 'person__';
    let person_data = ae.util.extract_prefixed_form_data({prefix:find_obj_type, form_data: form_data, trim_values: true, log_lvl: 0});

    // find_obj_type = 'user__';
    // let user_data = ae.util.extract_prefixed_form_data({prefix:find_obj_type, form_data: form_data, trim_values: true, log_lvl: 1});

    find_obj_type = 'contact__';
    let contact_data = ae.util.extract_prefixed_form_data({prefix:find_obj_type, form_data: form_data, trim_values: true, log_lvl: 0});

    find_obj_type = 'address__';
    let address_data = ae.util.extract_prefixed_form_data({prefix:find_obj_type, form_data: form_data, trim_values: true, log_lvl: 0});

    // person_data['user'] = user_data;
    contact_data['address'] = address_data;
    person_data['contact'] = contact_data;

    // Join with the raw form data with person_name. person_name is a select list of the processed name parts from Svelte element_person_name and JS process_person_name
    // person_data = Object.assign(person_data, person_name);

    console.log(person_data);

    // let find_obj_type = 'person__';
    // let person_data = ae.util.extract_prefixed_form_data({prefix: find_obj_type, form_data: form_data, trim_values: true, log_lvl: 2});
    // console.log(person_data);

    if (!$slct.person_id) {
        console.log('No Person ID found! Create...');
        person_data['enable'] = true;

        create_person_obj_promise = create_person_obj({account_id: $slct.account_id, data: person_data, return_obj: false, log_lvl: 1});

        create_person_obj_promise.then(function (results) {
            console.log(results);
            $slct.person_id = results;
            // $slct_obj.person = create_person_obj_promise;

            dispatch(
                'person_obj_created',
                {
                    person_id: $slct.person_id,
                    // person_obj: $slct_obj.person,
                }
            );
            return true;
        })
        .catch(function (error) {
            console.log(error);
            return false;
        });


    } else {
        console.log('Person ID found! Update...');
        person_data['contact_id_random'] = person_data['contact']['contact_id_random'];

        update_person_obj_promise = update_person_obj({person_id: $slct.person_id, data: person_data, log_lvl: 1});

        update_person_obj_promise.then(function (results) {
            console.log(results);

            dispatch(
                'person_obj_updated',
                {
                    person_id: $slct.person_id,
                }
            );
        })
        .catch(function (error) {
            console.log(error);
            return false;
        });
    }

    // post_promise = null;
    // update_person_obj_promise = null;

    disable_submit_btn = false;

    return true;
}

async function handle_delete_person_obj({person_id}) {
    console.log('*** handle_delete_person_obj() ***');

    person_obj_delete_promise = delete_person_obj({person_id:person_id});

    person_obj_delete_promise.then(function (person_obj_result) {
        if (!person_obj_result) {
            console.log('The result was null or false when trying to delete the person presenter.');
            return;
        }

        $slct.person_id = null;
        $slct.person_obj = null;

        dispatch(
            'person_obj_deleted',
            {
                person_id: person_id,
            }
        );
    })
    .catch(function (error) {
        console.log('Something went wrong deleting the person presenter.');
        console.log(error);
        return false;
    });

    return person_obj_delete_promise;
}
</script>


<section class="svelte_component person_crud {container_class_li.join(' ')}">

    <form on:submit|preventDefault={handle_submit_form} class="form-horizontal">

        {#await update_person_obj_promise}
            <div class="awaiting alert_msg_pulse" out:fade={{ duration: 2000 }}>Saving...</div>
        {:then}
            {#if update_person_obj_promise}
                <div class="awaiting" out:fade={{ duration: 2000 }}>Finished saving</div>
            {:else}
                <!-- <div class="awaiting" out:fade={{ duration: 2000 }}>Nothing here yet</div> -->
            {/if}
        {/await}


        <Element_input {...ae.input_template['person']['person_id_random']} bind:value={$slct.person_obj.person_id_random} type="hidden" />

        <section class="person_obj__person_check person_check mb-2">
            <fieldset class="form-group">
                <label class="col-form-label"><h3>Name</h3></label>


                <div class="row mb-1 col-md-12 col-lg-12">
                    <Element_input {...ae.input_template['person']['given_name']} content_layout={'floating_input'} use_name_prefix={true} bind:value={$slct.person_obj.given_name} container_class_li={['mb-1', 'col-md-6', 'col-lg-6']} label={"Given/First name"}  required />


                    <!-- <Element_input {...ae.input_template['person']['middle_name']} content_layout={'floating_input'} use_name_prefix={true} bind:value={$slct.person_obj.middle_name} container_class_li={['mb-1', 'col-md-6', 'col-lg-6']} label={"Middle name"} /> -->


                    <Element_input {...ae.input_template['person']['family_name']} content_layout={'floating_input'} use_name_prefix={true} bind:value={$slct.person_obj.family_name} container_class_li={['mb-1', 'col-md-6', 'col-lg-6']} label={"Family/Last name"} />
                </div>

                <!-- NOTE: Hide some fields if they do not already have a person record. Only collecting the minimum amount of information. Make ordering checkout faster. -->
                {#if $slct.person_obj.person_id_random}
                <div class="row mb-1 col-md-12 col-lg-12">
                    <Element_input {...ae.input_template['person']['display_name']} content_layout={'floating_input'} use_name_prefix={true} bind:value={$slct.person_obj.display_name} container_class_li={['mb-1', 'col-md-6', 'col-lg-6']} />

                    <div class="bg_warning">NOTE: Your display name will be used for things like a name on certificate for CE credits. It should probably include your professional designation(s). Examples: Jane Doe MD; Dr. James Doe; John Doe MBA; Julian Doe PhD</div>
                </div>
                {/if}
            </fieldset>

            <!-- NOTE: Hide some fields if they do not already have a person record. Only collecting the minimum amount of information. Make ordering checkout faster. -->
            {#if $slct.person_obj.person_id_random}
            <fieldset class="form-group">
                <label class="col-form-label"><h3>Affiliations</h3></label>


                <Element_input {...ae.input_template['person']['affiliations']} content_layout={'floating_input'} use_name_prefix={true} bind:value={$slct.person_obj.affiliations} container_class_li={['mb-1', 'col-md-8', 'col-lg-6']} label={"Your organization/company affiliations"} />
            </fieldset>
            {/if}

            <fieldset class="form-group">
                <label class="col-form-label"><h3>Contact</h3></label>


                <Element_input {...ae.input_template['contact']['id_random']} use_name_prefix={true} bind:value={$slct.person_obj.contact_id_random} />


                <Element_input {...ae.input_template['contact']['email']} content_layout={'floating_input'} use_name_prefix={true} bind:value={$slct.person_obj.contact.email} container_class_li={['mb-1', 'col-md-8', 'col-lg-6']} required />

                <!-- NOTE: Hide some fields if they do not already have a person record. Only collecting the minimum amount of information. Make ordering checkout faster. -->
                {#if $slct.person_obj.person_id_random}
                <Element_input {...ae.input_template['contact']['phone_mobile']} content_layout={'floating_input'} use_name_prefix={true} bind:value={$slct.person_obj.contact.phone_mobile} container_class_li={['mb-1', 'col-md-8', 'col-lg-6']} label={"Phone number"} />
                {/if}
            </fieldset>

            <!-- NOTE: Hide some fields if they do not already have a person record. Only collecting the minimum amount of information. Make ordering checkout faster. -->
            {#if $slct.person_obj.person_id_random}
            <fieldset id="address_obj" class="form-group address_obj"> <!-- NOTE: For some reason we need "row" with Bootstrap 5 for it to look correct here. -->

                <label class="col-form-label"><h3>Address</h3></label>


                <Element_input {...ae.input_template['address']['id_random']} use_name_prefix={true} bind:value={$slct.person_obj.contact.address_id_random} />

                <Element_input {...ae.input_template['address']['name']} content_layout={'floating_input'} use_name_prefix={true} bind:value={$slct.person_obj.contact.address.name} container_class_li={['mb-1', 'col-md-8', 'col-lg-6']} label={"Location name"} />


                <Element_input {...ae.input_template['address']['line_1']} content_layout={'floating_input'} use_name_prefix={true} bind:value={$slct.person_obj.contact.address.line_1} container_class_li={['mb-1', 'col-md-8', 'col-lg-6']} label=
                {"Address line 1"} />
                <Element_input {...ae.input_template['address']['line_2']} content_layout={'floating_input'} use_name_prefix={true} bind:value={$slct.person_obj.contact.address.line_2} container_class_li={['mb-1', 'col-md-8', 'col-lg-6']} label=
                {"Address line 2"} />
                <Element_input {...ae.input_template['address']['line_3']} content_layout={'floating_input'} use_name_prefix={true} bind:value={$slct.person_obj.contact.address.line_3} container_class_li={['mb-1', 'col-md-8', 'col-lg-6']} label={"Address line 3"} />

                <div class="row">
                    <Element_input {...ae.input_template['address']['city']} content_layout={'floating_input'} use_name_prefix={true} bind:value={$slct.person_obj.contact.address.city} container_class_li={['mb-1', 'col-md-6', 'col-lg-4']} label={"Name of the city"} />

                    <!-- NOTE: Hide some fields if they do not already have a person record. Only collecting the minimum amount of information. Make ordering checkout faster. -->
                    <!-- {#if $slct.person_obj.person_id_random}
                    {#if lu_country_subdivision_li}
                        <div class="form-floating mb-1 col-sm-6 col-md-4 col-lg-4">
                            <select class="form-select" id="person__contact__address__country_subdivision_code--input" name="person__contact__address__country_subdivision_code" aria-label="Select the state or province for the meeting">
                                <option value="">-- None --</option>
                                {#each lu_country_subdivision_li as lu_country_subdivision, index}
                                    <option value={lu_country_subdivision.code}> {lu_country_subdivision.country_alpha_2_code}  - {lu_country_subdivision.name}</option>
                                {/each}
                            </select>
                            <label for="person__contact__address__country_subdivision_code--input" class="">State or province</label>
                        </div>

                    {:else}
                    <Element_input {...ae.input_template['address']['country_subdivision_code']} content_layout={'floating_input'} use_name_prefix={true} bind:value={$slct.person_obj.contact.address.country_subdivision_code} container_class_li={['mb-1', 'col-sm-6', 'col-md-4', 'col-lg-4']} label={"State or province code"} />
                    {/if}
                    {/if} -->


                    <Element_input {...ae.input_template['address']['postal_code']} content_layout={'floating_input'} use_name_prefix={true} bind:value={$slct.person_obj.contact.address.postal_code} container_class_li={['mb-1', 'col-sm-6', 'col-md-4', 'col-lg-4']} label={"Postal code or zip code"} required />
                </div>

                <!-- {#if lu_country_li}
                    <div class="form-floating mb-1 col-md-6">
                        <select class="form-select" id="person__contact__address__country_alpha_2_code--input" name="person__contact__address__country_alpha_2_code" aria-label="Select the country for the meeting">
                            <option value="">-- None --</option>
                            {#each lu_country_li as lu_country, index}

                                <option value={lu_country.alpha_2_code}>{lu_country.english_short_name}</option>
                            {/each}
                        </select>
                        <label for="person__contact__address__country_alpha_2_code--input" class="">Country</label>
                    </div>

                {:else}
                <Element_input {...ae.input_template['address']['country_alpha_2_code']} content_layout={'floating_input'} use_name_prefix={true} bind:value={$slct.person_obj.contact.address.country_alpha_2_code} container_class_li={['mb-1']} required />
                {/if} -->
            </fieldset>
            {/if}
        </section>

        <div class="form-group">
            <button type="submit" class="ae_btn btn_outline_success"><span class="fas fa-check"></span> Save Changes</button>
        </div>

    </form>

    {#if $client.administrator_access || $ae_com.administrator_access}
    {#if $slct.person_id}
    <button
        on:click={() => {
            if (!confirm('Are you sure you want to delete this person presenter?')) {return false;}
            handle_delete_person_obj({person_id: $slct.person_id});
        }}
        class="btn btn_sm btn_outline_warning float_right" type="button">
        <span class="fas fa-minus"></span> Delete
    </button>
    {/if}
    {/if}

</section>


<style>
.svelte_component.person_add {
    /* outline: dashed thin pink; */
}
</style>
