// console.log('*** stores_admin_api.js Stores ***');
import { get, readable, writable } from 'svelte/store';
import { account_id, cfg } from '../stores';
import { api } from 'aether_npm_lib';


/* BEGIN: Basic CRUD Object Related Functions */


// Updated 2022-01-14
export let load_obj = async function load_obj({obj_type, obj_id, params={}, log_lvl=0}) {
    console.log('*** stores_admin_api.js: load_obj() ***');

    let endpoint = '';

    endpoint = `/crud/${obj_type}/${obj_id}`;

    // if (inc_x_list) {
    //     params['inc_x_list'] = inc_x_list;
    // }
    // if (inc_x) {
    //    params['inc_x'] = inc_x;
    // }

    let obj_get_promise = await api.get_object({api_cfg: get(cfg).api, endpoint: endpoint, params: params, log_lvl: log_lvl});

    let obj = obj_get_promise;
    return obj;
}


// Updated 2022-01-14
export let get_obj_li = async function get_obj_li({obj_type, for_obj_type, for_obj_id, inc_x=false, params={}, log_lvl=0}) {
    console.log('*** stores_admin_api.js: get_obj_li() ***');

    let endpoint = '';

    endpoint = `/crud/${obj_type}/list`;
    params['for_obj_type'] = for_obj_type;
    params['for_obj_id'] = for_obj_id;

    // if (inc_x_list) {
    //     params['inc_x_list'] = inc_x_list;
    // }
    // if (inc_x) {
    //    params['inc_x'] = inc_x;
    // }

    let obj_get_promise = await api.get_object({api_cfg: get(cfg).api, endpoint: endpoint, params: params, log_lvl: log_lvl});

    let obj = obj_get_promise;
    return obj;
}


// Updated 2022-01-14
export let update_obj_prop = async function update_obj_prop({for_obj_type, for_obj_id, prop_name, prop_value, params={}, log_lvl=0}) {
    console.log('*** stores_admin_api.js: update_obj_prop() ***');

    return false;
}