// console.log('*** stores_person_defaults.js Stores ***');
import { get, readable, writable } from 'svelte/store';

import { ae, api, Element_modal_v3 } from 'aether_npm_lib';

import { slct_person_id } from './stores_person.js';

let default_data = {};

let default_btn_label_dict_li = {};
let default_btn_title_dict_li = {};

// *** Person ***
// default_btn_label_dict_li['person_back_to'] = '<span class="fas fa-arrow-left"></span> Back to Person';
// default_btn_title_dict_li['person_back_to'] = 'Back to person page listing the sessions';
default_btn_label_dict_li['person_create'] = '<span class="fas fa-plus"></span> Create Person';
default_btn_title_dict_li['person_create'] = 'Create Person';
default_btn_label_dict_li['person_edit'] = '<span class="fas fa-edit"></span> Edit Person';
default_btn_title_dict_li['person_edit'] = 'Edit the person';
// default_btn_label_dict_li['person_manage'] = '<span class="fas fa-tools"></span> Manage Person';
// default_btn_title_dict_li['person_manage'] = 'Manage the person';
default_btn_label_dict_li['person_view'] = '<span class="fas fa-eye"></span> View Person';
default_btn_title_dict_li['person_view'] = 'View the person';


// let person_back_to_btn_data = Object.assign({}, ae.defaults.default_btn);
// person_back_to_btn_data['label'] = default_btn_label_dict_li['person_back_to'];
// person_back_to_btn_data['title'] = default_btn_title_dict_li['person_back_to'];
// person_back_to_btn_data['href'] = `/person/${get(slct_person_id)}`;
// // person_back_to_btn_data['class_li'] = ['ae_btn', 'btn_md', 'btn_secondary'];
// person_back_to_btn_data['class_li'] = ['ae_btn'];
// default_data['person_back_to_btn'] = person_back_to_btn_data;

let person_create_btn_modal_data = Object.assign({}, ae.defaults.default_btn);
person_create_btn_modal_data['type'] = 'button';
person_create_btn_modal_data['callback'] = null; // function create_btn_callback()
person_create_btn_modal_data['permission_li'] = ['administrator'];
person_create_btn_modal_data['label'] = default_btn_label_dict_li['person_create'];
person_create_btn_modal_data['title'] = default_btn_title_dict_li['person_create'];
person_create_btn_modal_data['href'] = null;
person_create_btn_modal_data['confirm'] = 'Are you sure you want to create a new person?';
person_create_btn_modal_data['class_li'] = ['ae_btn'];
default_data['person_create_btn_modal'] = person_create_btn_modal_data;

let person_edit_btn_data = Object.assign({}, ae.defaults.default_btn);
person_edit_btn_data['permission_li'] = ['manager'];
person_edit_btn_data['label'] = default_btn_label_dict_li['person_edit'];
person_edit_btn_data['title'] = default_btn_title_dict_li['person_edit'];
person_edit_btn_data['href'] = `/person/${get(slct_person_id)}`;
person_edit_btn_data['confirm'] = 'Are you sure you want to edit this person?';
person_edit_btn_data['class_li'] = ['ae_btn'];
default_data['person_edit_btn'] = person_edit_btn_data;

let person_manage_btn_data = Object.assign({}, ae.defaults.default_btn);
person_manage_btn_data['permission_li'] = ['manager'];
person_manage_btn_data['label'] = default_btn_label_dict_li['person_manage'];
person_manage_btn_data['title'] = default_btn_title_dict_li['person_manage'];
person_manage_btn_data['href'] = `/person/${get(slct_person_id)}`;
person_manage_btn_data['class_li'] = ['ae_btn'];
default_data['person_manage_btn'] = person_manage_btn_data;

let person_view_id_btn_data = Object.assign({}, ae.defaults.default_btn);
person_view_id_btn_data['label'] = default_btn_label_dict_li['person_view'];
person_view_id_btn_data['title'] = default_btn_title_dict_li['person_view'];
person_view_id_btn_data['href'] = `/person/${get(slct_person_id)}`;
// person_view_id_btn_data['class_li'] = ['ae_btn', 'btn_md', 'btn_secondary'];
person_view_id_btn_data['class_li'] = ['ae_btn'];
default_data['person_view_id_btn'] = person_view_id_btn_data;

// *** Person Badges ***
default_btn_label_dict_li['person_badge_create'] = '<span class="fas fa-plus"></span> Create New Badge';
default_btn_label_dict_li['person_badge_delete'] = '<span class="fas fa-minus"></span> Delete Badge';
default_btn_label_dict_li['person_badge_edit'] = '<span class="fas fa-edit"></span> Edit Badge';
default_btn_label_dict_li['person_badge_update'] = '<span class="fas fa-check"></span> Update Badge';

// *** Person Locations ***
default_btn_label_dict_li['person_location_create'] = '<span class="fas fa-plus"></span> Create New Location';
default_btn_label_dict_li['person_location_edit'] = '<span class="fas fa-edit"></span> Edit Location';
default_btn_label_dict_li['person_location_view_li'] = '<span class="fas fa-eye"></span> View Locations';
default_btn_title_dict_li['person_location_view_li'] = 'View the locations associated with this person';

let person_location_create_btn_data = Object.assign({}, ae.defaults.default_btn);
person_location_create_btn_data['permission_li'] = ['manager'];
person_location_create_btn_data['label'] = default_btn_label_dict_li['person_location_create'];
person_location_create_btn_data['title'] = default_btn_title_dict_li['person_location_create'];
person_location_create_btn_data['href'] = `/person/locations/${get(slct_person_id)}`;
person_location_create_btn_data['confirm'] = 'Are you sure you want to create a new location?';
person_location_create_btn_data['class_li'] = ['ae_btn'];
default_data['person_location_create_btn'] = person_location_create_btn_data;

let person_location_create_btn_modal_data = Object.assign({}, ae.defaults.default_btn);
person_location_create_btn_modal_data['type'] = 'button';
person_location_create_btn_modal_data['callback'] = null; // function create_btn_callback()
person_location_create_btn_modal_data['permission_li'] = ['administrator'];
person_location_create_btn_modal_data['label'] = default_btn_label_dict_li['person_location_create'];
person_location_create_btn_modal_data['title'] = default_btn_title_dict_li['person_location_create'];
person_location_create_btn_modal_data['href'] = null;
person_location_create_btn_modal_data['confirm'] = 'Are you sure you want to create a new location?';
person_location_create_btn_modal_data['class_li'] = ['ae_btn'];
default_data['person_location_create_btn_modal'] = person_location_create_btn_modal_data;

let person_location_edit_btn_modal_data = Object.assign({}, ae.defaults.default_btn);
person_location_edit_btn_modal_data['type'] = 'button';
person_location_edit_btn_modal_data['callback'] = null; // function edit_btn_callback()
person_location_edit_btn_modal_data['permission_li'] = ['administrator'];
person_location_edit_btn_modal_data['label'] = default_btn_label_dict_li['person_location_edit'];
person_location_edit_btn_modal_data['title'] = default_btn_title_dict_li['person_location_edit'];
person_location_edit_btn_modal_data['href'] = null;
// person_location_edit_btn_modal_data['confirm'] = 'Are you sure you want to edit a new location?';
person_location_edit_btn_modal_data['class_li'] = ['ae_btn'];
default_data['person_location_edit_btn_modal'] = person_location_edit_btn_modal_data;

let person_location_view_li_btn_data = Object.assign({}, ae.defaults.default_btn);
person_location_view_li_btn_data['permission_li'] = ['administrator'];
person_location_view_li_btn_data['label'] = default_btn_label_dict_li['person_location_view_li'];
person_location_view_li_btn_data['title'] = default_btn_title_dict_li['person_location_view_li'];
person_location_view_li_btn_data['href'] = `/person/locations/${get(slct_person_id)}`;
person_location_view_li_btn_data['class_li'] = ['ae_btn'];
default_data['person_location_view_li_btn'] = person_location_view_li_btn_data;

// *** Person Presentations ***
default_btn_label_dict_li['person_presentation_create'] = '<span class="fas fa-plus"></span> Create New Presentation';
default_btn_label_dict_li['person_presentation_delete'] = '<span class="fas fa-minus"></span> Delete Presentation';
default_btn_label_dict_li['person_presentation_edit'] = '<span class="fas fa-edit"></span> Edit Presentation';
default_btn_label_dict_li['person_presentation_update'] = '<span class="fas fa-check"></span> Update Presentation';

// *** Person Presenters ***
default_btn_label_dict_li['person_presenter_create'] = '<span class="fas fa-plus"></span> Create New Presenter';
default_btn_label_dict_li['person_presenter_delete'] = '<span class="fas fa-minus"></span> Delete Presenter';
default_btn_label_dict_li['person_presenter_edit'] = '<span class="fas fa-edit"></span> Edit Presenter';
default_btn_label_dict_li['person_presenter_update'] = '<span class="fas fa-check"></span> Update Presenter';

let person_presenter_edit_btn_modal_data = Object.assign({}, ae.defaults.default_btn);
person_presenter_edit_btn_modal_data['type'] = 'button';
person_presenter_edit_btn_modal_data['callback'] = null; // function edit_btn_callback()
person_presenter_edit_btn_modal_data['permission_li'] = ['administrator'];
person_presenter_edit_btn_modal_data['label'] = default_btn_label_dict_li['person_presenter_edit'];
person_presenter_edit_btn_modal_data['title'] = default_btn_title_dict_li['person_presenter_edit'];
person_presenter_edit_btn_modal_data['href'] = null;
// person_presenter_edit_btn_modal_data['confirm'] = 'Are you sure you want to edit a new presenter?';
person_presenter_edit_btn_modal_data['class_li'] = ['ae_btn'];
default_data['person_presenter_edit_btn_modal'] = person_presenter_edit_btn_modal_data;


// *** Person Sessions ***
default_btn_label_dict_li['person_session_create'] = '<span class="fas fa-plus"></span> Create New Session';
default_btn_title_dict_li['person_session_create'] = 'Create a new session';
default_btn_label_dict_li['person_session_delete'] = '<span class="fas fa-minus"></span> Delete Session';
default_btn_label_dict_li['person_session_edit'] = '<span class="fas fa-edit"></span> Edit Session';
default_btn_label_dict_li['person_session_update'] = '<span class="fas fa-check"></span> Update Session';

let person_session_create_btn_data = Object.assign({}, ae.defaults.default_btn);
person_session_create_btn_data['permission_li'] = ['administrator'];
person_session_create_btn_data['label'] = default_btn_label_dict_li['person_session_create'];
person_session_create_btn_data['title'] = default_btn_title_dict_li['person_session_create'];
person_session_create_btn_data['href'] = `/person/session/create?person_id_random=${get(slct_person_id)}`;
person_session_create_btn_data['confirm'] = 'Are you sure you want to create a new session?';
person_session_create_btn_data['class_li'] = ['ae_btn'];
default_data['person_session_create_btn'] = person_session_create_btn_data;


let person_session_create_btn_modal_data = Object.assign({}, ae.defaults.default_btn);
person_session_create_btn_modal_data['type'] = 'button';
person_session_create_btn_modal_data['callback'] = null; // function create_btn_callback()
person_session_create_btn_modal_data['permission_li'] = ['administrator'];
person_session_create_btn_modal_data['label'] = default_btn_label_dict_li['person_session_create'];
person_session_create_btn_modal_data['title'] = default_btn_title_dict_li['person_session_create'];
person_session_create_btn_modal_data['href'] = null;
person_session_create_btn_modal_data['confirm'] = 'Are you sure you want to create a new session?';
person_session_create_btn_modal_data['class_li'] = ['ae_btn'];
default_data['person_session_create_btn_modal'] = person_session_create_btn_modal_data;

let person_session_edit_btn_data = Object.assign({}, ae.defaults.default_btn);
person_session_edit_btn_data['permission_li'] = ['administrator'];
person_session_edit_btn_data['label'] = default_btn_label_dict_li['person_session_edit'];
person_session_edit_btn_data['title'] = default_btn_title_dict_li['person_session_edit'];
person_session_edit_btn_data['href'] = `/person/session/edit?person_id_random=${get(slct_person_id)}`;
person_session_edit_btn_data['confirm'] = 'Are you sure you want to edit a new session?';
person_session_edit_btn_data['class_li'] = ['ae_btn'];
default_data['person_session_edit_btn'] = person_session_edit_btn_data;

let person_session_edit_btn_modal_data = Object.assign({}, ae.defaults.default_btn);
person_session_edit_btn_modal_data['type'] = 'button';
person_session_edit_btn_modal_data['callback'] = null; // function edit_btn_callback()
person_session_edit_btn_modal_data['permission_li'] = ['administrator'];
person_session_edit_btn_modal_data['label'] = default_btn_label_dict_li['person_session_edit'];
person_session_edit_btn_modal_data['title'] = default_btn_title_dict_li['person_session_edit'];
person_session_edit_btn_modal_data['href'] = null;
// person_session_edit_btn_modal_data['confirm'] = 'Are you sure you want to edit a new session?';
person_session_edit_btn_modal_data['class_li'] = ['ae_btn'];
default_data['person_session_edit_btn_modal'] = person_session_edit_btn_modal_data;

default_data['btn_label_dict_li'] = default_btn_label_dict_li;
default_data['btn_title_dict_li'] = default_btn_title_dict_li;

// default_data['person_menu'] = person_menu_data;

export let person_defaults = writable(default_data);
