<script lang="ts">
import { onMount } from 'svelte';

import { ae, Element_input, Element_person_name } from 'aether_npm_lib';

import { account_id, client, page } from '../stores';

import { slct_person_id, slct_person_obj } from '../person/stores_person.js';
import { email_person_obj_auth_key_url, load_person_obj, lookup_person_obj_email, create_person_obj } from '../person/stores_person_api.js';
import { slct_user_id, slct_user_obj } from '../user/stores_user.js';
import { email_user_obj_auth_key_url, load_user_obj } from '../user/stores_user_api.js';


let person_id: string = null;

let person_obj: object = null;
let person_obj_li: string[] = null;

let person_data: object = {}; // Pass if creating a new obj with some predefined data (defaults)

let person_obj_get_promise = null;

let person_obj_li_get_promise;

let person_data_post_promise;

let person_name = { informal_name:'', given_name:'', middle_name:'', family_name:'', display_name:'', full_name:'', informal_full_name:'' };


/* BEGIN: Lifecycle functions (onMount, beforeUpdate, afterUpdate,
onDestroy, tick) */
onMount(async () => {
    console.log('Person User Create component has mounted');
});
/* END: Lifecycle functions (onMount, beforeUpdate, afterUpdate,
onDestroy, tick) */

/* BEGIN: Handle requests (archive, create, hide, remove, select, update, POST, PATCH, GET, DELETE) */
/* END: Handle requests (archive, create, hide, remove, select, update, POST, PATCH, GET, DELETE) */

/* BEGIN: Handle other local actions (show/hide form, process data) */
async function handle_submit_form(event) {
    console.log('*** handle_submit_form() ***');

    let email = event.target.email.value;

    let params = { inc_address: true, inc_contact: true, inc_user: true, enabled: 'all', hidden: 'all', limit: 100 }
    params['email'] = email;
    console.log(params);

    // person_obj_li_get_promise = await lookup_person_obj_email({params: params, log_lvl: 2});
    let person_obj_li_lu_result = await lookup_person_obj_email({params: params, log_lvl: 2});
    // console.log(person_obj_li_get_promise);

    person_obj_li = person_obj_li_lu_result;

    // person_obj_li = person_obj_li_get_promise.then(function(person_obj_li) {
        console.log(`.then .....`);
        if (Array.isArray(person_obj_li)) {
            console.log(`Got an array result!`);
            console.log(person_obj_li);

            if (person_obj_li.length == 1) {
                console.log(`Found 1 record`);
                params = {};
                let user_id = person_obj_li[0].user_id_random;
                await email_user_obj_auth_key_url({user_id:user_id, root_url:$page.current_url_root, params: params, log_lvl: 2});
            } else {
                console.log(`Found ${person_obj_li.length} records`);
            }

            return person_obj_li;
        } else {
            console.log(`No records found. Trying to create a new person/user account and send a continue email.`);

            let person_data = { };
            person_data = person_name;
            person_data['enable'] = true;
            person_data['contact'] = { };
            person_data['user'] = { };

            let contact_data = {}
            contact_data['email'] = email;
            person_data['contact'] = contact_data;

            let user_data = {}
            user_data['name'] = person_name.full_name;
            user_data['username'] = email;
            user_data['email'] = email;
            user_data['email_verified'] = true;
            user_data['enable'] = true;
            user_data['allow_auth_key'] = true;
            person_data['user'] = user_data;

            // Include more form fields here in the future?
            person_data['account_id_random'] = $account_id;
            console.log(person_data);

            // person_data_post_promise = create_person_obj({params: params, data:person_data, log_lvl: 2});
            let person_data_create_result = await create_person_obj({account_id: $account_id, params: params, data:person_data, log_lvl: 2});
            // console.log(person_data_post_promise);

            person_id = person_data_create_result;

            // person_id = person_data_post_promise.then(function(person_id) {
                console.log(person_id);

                if (person_id) {
                    params = {};
                    await email_person_obj_auth_key_url({person_id:person_id, root_url:$page.current_url_root, params: params, log_lvl: 2});

                    return true;
                } else {
                    return false;
                }
            // }, function(result) {
            //     // not called
            // });

            return 'Hopefully sent create URL email';
        }


    // }, function(result) {
    //     // not called
    // });

    // person_obj_li = person_obj_li_get_promise;

    // dispatch('person_user_email_sent', {
    //     person_id: person_obj.person_id_random,
    //     given_name: person_obj.given_name,
    //     full_name: person_obj.full_name,
    //     person_obj: person_obj,
    // });
    console.log('HERE????');
}

function handle_person_name_change(event) {
    console.log('*** handle_person_name_change() ***');
    person_name = event.detail.person_name;
    console.log(person_name);
}
/* END: Handle other local actions (show/hide form, process data) */

/* BEGIN: Handle children events (archived, canceled, closed, created, deleted, hidden, updated) */
/* END: Handle children events (archived, canceled, closed, created, deleted, hidden, updated) */
</script>


<section class="obj_type_main person_user_main container">
    <header>
        <h3>Create New Account</h3>
    </header>

    <p>To start creating a new account please enter your name and email address. A one time use link will be emailed to the address entered below when you click the "Email Account Creation Link" button. Follow the link to verify your email address and continue the account creation.</p>

    <section class="person_user_main main">
        <form on:submit|preventDefault={handle_submit_form} class="" on:keydown={e => e.key === 'Escape' && handle_canceled_form}>
            <Element_person_name {person_name} on:person_name_change={handle_person_name_change} />
            <Element_input {...ae.input_template['contact']['email']} content_layout={'floating_input'} container_class_li={['mb-1', 'col-md-4', 'col-lg-3']} required={true} />

            <button type="submit" class="ae_btn btn_primary"><span class="fas fa-link"></span> Email Account Creation Link</button>
        </form>

        {#await person_obj_li_get_promise}
            <div>Loading...</div>
        {:then person_obj_li}
            {#if person_obj_li}
                <div>An email is sent if a record with the email address provided was found. For privacy reasons we avoid showing the result of the query because anyone can try to search for an email address. <span class="fas fa-user-shield"></span></div>

                <div>Search complete <span class="fas fa-surprise"></span></div>
                <ul>
                    {#each person_obj_li as person_obj, i }
                        <li class="warning">PID: {person_obj.person_id_random} UID: {person_obj.user_id_random} {person_obj.given_name} {person_obj.family_name} {person_obj.email} {person_obj.created_on}</li>
                    {/each}
                </ul>
            {:else if person_obj_li === null}
                <div>Search complete <span class="fas fa-smile"></span></div>
            {:else}
                <!-- <div>Got an unexpected response. Search complete? <span class="fas fa-frown"></span></div> -->
            {/if}
        {:catch error}
            <div class="error">{error.message}</div>
        {/await}

    </section> <!-- END person_user_main -->
</section>


<style>
</style>
